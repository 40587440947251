import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  addBankDetails,
  getTransactions,
  getBankInfo,
  updateBankDetails,
  deleteBankDetails,
  getActivity,
  getAchievements,
} from "../api/appApi"

export interface AppStateInterface {
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted"
  addBankDetails: any
  updateBankDetails: any
  deleteBankDetails: any
  transactions: any
  BankInfo: any
  activity: any
  achievements: any
}

const initialState: AppStateInterface = {
  status: "idle",
  BankInfo: [],
  activity: {},
  addBankDetails: {},
  updateBankDetails: {},
  deleteBankDetails: {},
  transactions: [],
  achievements: {},
}

export const getBankInfoAsync = createAsyncThunk("getBankInfo", async () => {
  const response = await getBankInfo()
  return response.data
})

export const addBankDetailAsync = createAsyncThunk(
  "addBankDetails",
  async (data: any) => {
    const response = await addBankDetails(data)
    return response.data
  }
)

export const updateBankDetailAsync = createAsyncThunk(
  "updateBankDetails",
  async (data: any) => {
    const response = await updateBankDetails(data)
    return response.data
  }
)

export const deleteBankDetailAsync = createAsyncThunk(
  "deleteBankDetails",
  async (data: any) => {
    const response = await deleteBankDetails(data)
    return response.data
  }
)

export const getTransactionAsync = createAsyncThunk(
  "getTransactions",
  async () => {
    const response = await getTransactions()
    return response.data
  }
)

export const getActivityAsync = createAsyncThunk("getActivity", async () => {
  const response = await getActivity()
  return response.data
})

export const getAchievementsAsync = createAsyncThunk(
  "getAchievements",
  async (params: any) => {
    const response = await getAchievements(params)
    return {
      params: params,
      data: response.data,
    }
  }
)

export const myAccountSlice = createSlice({
  name: "myAccount",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    // get Banks
    builder.addCase(getBankInfoAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getBankInfoAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.BankInfo = action.payload.data
    })
    builder.addCase(getBankInfoAsync.rejected, (state) => {
      state.status = "failed"
    })

    // Add Bank
    builder.addCase(addBankDetailAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(addBankDetailAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.addBankDetails = action.payload
    })
    builder.addCase(addBankDetailAsync.rejected, (state) => {
      state.status = "failed"
    })

    // Update Bank
    builder.addCase(updateBankDetailAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(updateBankDetailAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.updateBankDetails = action.payload
    })
    builder.addCase(updateBankDetailAsync.rejected, (state) => {
      state.status = "failed"
    })

    // Delete Bank
    builder.addCase(deleteBankDetailAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(deleteBankDetailAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.deleteBankDetails = action.payload
    })
    builder.addCase(deleteBankDetailAsync.rejected, (state) => {
      state.status = "failed"
    })

    // get Transactions
    builder.addCase(getTransactionAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getTransactionAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.transactions = action.payload.data
    })
    builder.addCase(getTransactionAsync.rejected, (state) => {
      state.status = "failed"
    })

    // get Activity
    builder.addCase(getActivityAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getActivityAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.activity = action.payload
    })
    builder.addCase(getActivityAsync.rejected, (state) => {
      state.status = "failed"
    })

    // get Achievements
    builder.addCase(getAchievementsAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getAchievementsAsync.fulfilled, (state, action) => {
      state.status = "idle"
      if (action.payload.params.page == 1) {
        state.achievements = action.payload.data.data
      } else {
        state.achievements = [...state.achievements, ...action.payload.data.data]
      }
      // state.achievements = action.payload;
    })
    builder.addCase(getAchievementsAsync.rejected, (state) => {
      state.status = "failed"
    })
  },
})

export const {} = myAccountSlice.actions
export const myAccountState = (state: any) => state.myAccount
export default myAccountSlice.reducer
