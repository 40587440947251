import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import appReducer from "./appSlice"
import cmsReducer from "./cmsSlice"
import contestReducer from "./contestSlice"
import contestRoundReducer from "./contestRoundSlice"
import myAccountReducer from "./myAccountSlice"
import sponsorReducer from "./sponsorSlice"
import cartReducer from "./cartSlice"
import orderReducer from "./myOrderSlice"
import podcastReducer from "./podcastSlice"
import videoReducer from "./videoSlice"

const store = configureStore({
  reducer: {
    app: appReducer,
    cms: cmsReducer,
    contest: contestReducer,
    contestRound: contestRoundReducer,
    myAccount: myAccountReducer,
    sponsor: sponsorReducer,
    cart: cartReducer,
    order: orderReducer,
    podcast: podcastReducer,
    video: videoReducer,
  },
})
export default store

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
