import React from "react"
import { AuthHeader } from "../component/authHeader"

const AppBody = ({ children }: any) => {
  return (
    <React.Fragment>
      <AuthHeader />
      {children}
    </React.Fragment>
  )
}
export default AppBody
