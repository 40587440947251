import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { signup } from '../api/appApi';
import { setEmail } from '../stores/appSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ErrorAlert from './errorAlert';
import { showErrorToast, showSuccessToast } from '../utils/commonFun';

export const Signup = (props?: any) => {

    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const [showPass, setShowPass] = useState(false);
    const [showPass1, setShowPass1] = useState(false);

    let passRegx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/g;
    let emailRegx = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const SignupSchema = Yup.object().shape({
        email: Yup.string().matches(emailRegx, t('invalid-email')).required(t('email-required')),
        nick_name: Yup.string().required(t('nickname-required')),
        password: Yup.string().matches(passRegx, t('invalid-password')).required(t('password-required')),
        confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Your confirm passwords do not match.').required(t('confirm-password-required')),
        date_of_birth: Yup.number().required().moreThan(1900, t('Year Of Birth should not be 1900 or less than 1900')).lessThan(2024, t("Year Of Birth should not be more than 2023")).required(t('date-of-birth-required')),
    });

    const onSubmitSignUpForm = async (values: any, actions: any) => {
        const data = {
            email: values.email,
            password: values.password,
            confirm_password: values.confirm_password,
            nick_name: values.nick_name,
            birth_year: values.date_of_birth,
        }
        const res = await signup(data);
        if (res.status === 201) {
            dispatch(setEmail(values.email));
            props?.setVisibleModel("verify-otp");
        } else if (res.status === 400) {
            showErrorToast(res.data.message);
        } else if (res.data.error && res.data.message) {
            const error: any = {};
            for (var i = 0; i < res.data.error.length; i++) {
                error[res.data.error[i].path] = res.data.error[i].msg;
            }
            actions.setErrors(error);
        } else {
            showErrorToast(t("Something wrong."));
        }
    }

    return (
        <>
            <div className="ss-login-bg"></div>
            <div id="Signup" className="ss-login">
                <div><a onClick={() => { props?.setVisibleModel("") }}><img src="/images/icons/login-close.svg" width="35" alt="close" /></a></div>
                <div className="mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="font26 font-w500 text-orange mb-2">{t('sign_up')}</div>
                                <div>{t("signup_info")}</div>
                                <div className="ss-hrline"></div>
                                <Formik
                                    initialValues={{
                                        email: "",
                                        password: "",
                                        confirm_password: "",
                                        nick_name: "",
                                        date_of_birth: "",
                                    }}
                                    validationSchema={SignupSchema}
                                    onSubmit={onSubmitSignUpForm}
                                >
                                    {({ errors, touched }) => {
                                        return (
                                            <Form>
                                                <ErrorAlert errors={errors} touched={touched} />
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">{t('email')}</label>
                                                            <Field name="email" type="email" className={errors.email && touched.email ? "form-control error" : "form-control"} placeholder={t('enter_your_email_id')} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">{t('nick_name')}</label>
                                                            <Field name="nick_name" type="text" className={errors.nick_name && touched.nick_name ? "form-control error" : "form-control"} placeholder={t('enter_your_nick_name')} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="mb-3 relative">
                                                            <label className="form-label">{t('password')}</label>
                                                            <Field name="password" type={showPass ? "text" : "password"} className={errors.password && touched.password ? "form-control error" : "form-control"} placeholder={t('password')} />
                                                            {showPass ?
                                                                <img onClick={() => { setShowPass(!showPass) }} src="/images/icons/eye.svg" alt="eye" className="ss-form-eye-close" />
                                                                :
                                                                <img onClick={() => { setShowPass(!showPass) }} src="/images/icons/eye-close.svg" alt="email" className="ss-form-eye-close" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mb-3 relative">
                                                            <label className="form-label">{t('confirm_password')}</label>
                                                            <Field name="confirm_password" type={showPass1 ? "text" : "password"} className={errors.confirm_password && touched.confirm_password ? "form-control error" : "form-control"} placeholder={t('confirm_password')} />
                                                            {showPass1 ?
                                                                <img onClick={() => { setShowPass1(!showPass1) }} src="/images/icons/eye.svg" alt="eye" className="ss-form-eye-close" />
                                                                :
                                                                <img onClick={() => { setShowPass1(!showPass1) }} src="/images/icons/eye-close.svg" alt="email" className="ss-form-eye-close" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mb-3 relative">
                                                            <label className="form-label">{t('Year of Birth')}</label>
                                                            <Field name="date_of_birth" type="number" className={errors.date_of_birth && touched.date_of_birth ? "form-control error" : "form-control"} placeholder={t('Year of Birth')} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-2">
                                                        <i> {t("date_of_birth_note")} </i>
                                                    </div>
                                                </div>

                                                <button type="submit" className="btn btn-orange d-block w-100 radius25 mt-3">{t('sign_up')}</button>
                                                <div className="font-w500 mt-4">{t('already_have_an_account')} <a href="#" onClick={() => { props.setVisibleModel("signin") }} className="link-orange">{t('log_in')}</a></div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}