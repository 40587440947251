import { useEffect, useState } from "react"
import Articals from "./articals"
import Podcast from "./podcast"
import VideoTab from "./video"
import { useTranslation } from "react-i18next"
import AxiosInstance from "../utils/AxiosInstance"
import RemoteLanguage from "./remoteLang"
import { useAppDispatch } from "../stores/hooks"

const HomePageTab = () => {
  const [viewType, setViewType] = useState("grid")
  const [activeTab, setActiveTab] = useState("Articles")
  const [filter, setFilter] = useState("All")
  const [sportId, setSportId] = useState("")
  const [SportList, setSportList] = useState([])
  const { t } = useTranslation()

  const getSportList = async () => {
    const res = await AxiosInstance.get("/sport/dropdown")
    if (res.status == 200) {
      setSportList(res.data.data)
    }
  }

  useEffect(() => {
    getSportList()
  }, [])

  useEffect(() => {
    setSportId("")
    setFilter("All")
  }, [activeTab])

  return (
    <div id="HomePageTab" className="homepagetab">
      <div className="tabviewbar">
        <div className="row align-content-center">
          <div className="col-lg-6">
            <ul className="resp-tabs-list hor_1">
              <li
                onClick={() => setActiveTab("Articles")}
                className={
                  activeTab == "Articles"
                    ? "resp-tab-item hor_1 resp-tab-active"
                    : "resp-tab-item hor_1"
                }
              >
                {t("articles")}
              </li>
              <li
                onClick={() => setActiveTab("Podcast")}
                className={
                  activeTab == "Podcast"
                    ? "tablineleft resp-tab-item hor_1 resp-tab-active"
                    : "tablineleft resp-tab-item hor_1"
                }
              >
                {t("podcast")}
              </li>
              <li
                onClick={() => setActiveTab("Video")}
                className={
                  activeTab == "Video"
                    ? "resp-tab-item hor_1 resp-tab-active"
                    : "resp-tab-item hor_1"
                }
              >
                {t("video")}
              </li>
            </ul>
          </div>
          <div className="filter-box  col-lg-6">
            <div className="filters">
              <ul className="filter-box-ul">
                <li>
                  {viewType == "grid" ? (
                    <a onClick={() => setViewType("list")}>
                      <img src="/images/icons/listing.svg" alt="list" />
                    </a>
                  ) : (
                    <a onClick={() => setViewType("grid")}>
                      <img src="/images/icons/grid.svg" alt="list" />
                    </a>
                  )}
                </li>
                <li className="me-3 ms-4">
                  <div className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-offset="10,20"
                    >
                      {/* <img src="/images/icons/shorting.svg" alt="shorting" />  */}
                      {filter == "NonPurchased" ? t("Unpurchased") : t(filter)}
                    </a>
                    <ul className="dropdown-menu mt-3">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            setFilter("All")
                          }}
                          href="#!"
                        >
                          {t("All")}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            setFilter("Purchased")
                          }}
                          href="#!"
                        >
                          {t("Purchased")}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            setFilter("NonPurchased")
                          }}
                          href="#!"
                        >
                          {t("Unpurchased")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="filter">
                  <a
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-offset="10,20"
                    className="btn btn-orange dropdown-toggle"
                  >
                    <img src="/images/icons/filters.svg" alt="filters" />{" "}
                    {t("Filters")}
                  </a>
                  <ul className="dropdown-menu">
                    <div className="mt-4">
                      <div>
                        <a
                          onClick={() => {
                            setSportId("")
                          }}
                          className="dropdown-item text-end text-decoration-underline"
                          href="#!"
                        >
                          {t("Clear Filters")}
                        </a>
                      </div>
                      <label className="font-w600 d-block mb-2">{("Sports")}</label>
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          {SportList.map((sport: any, index: number) => {
                            return (
                              <li
                                onClick={() => {
                                  setSportId(sport.sport_id)
                                }}
                                key={`sport_${index}`}
                                className={"list-group-item"}
                              >
                                <a
                                  href="#!"
                                  className={
                                    sport.sport_id == sportId
                                      ? "orange-text font-w500"
                                      : ""
                                  }
                                >
                                  {" "}
                                  <RemoteLanguage field={sport.sport_name} />
                                  {/* <span>(25)</span> */}
                                </a>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {activeTab === "Articles" ? (
        <Articals filter={filter} sportId={sportId} viewType={viewType} />
      ) : activeTab === "Podcast" ? (
        <Podcast viewType={viewType} filter={filter} sportId={sportId} />
      ) : (
        <VideoTab viewType={viewType} filter={filter} sportId={sportId} />
      )}
    </div>
  )
}
export default HomePageTab
