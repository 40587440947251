import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cmsAccessBuy, getCmsById, getCmsList, getCmsListByLangCode, getPageBySlug, getPopularCMS } from "../api/appApi";

export interface AppStateInterface {
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  cmsList: any;
  cmsListByLangCode: any;
  cmsDetail: any;
  cmsAccessBuy: any;
  popularCMS: any;
  pageBySlug: any;
  cmsListCount: number;
  showPerchaseModal : any;
}

const initialState: AppStateInterface = {
  status: "idle",
  cmsList: [],
  cmsListByLangCode: [],
  cmsDetail: {},
  cmsAccessBuy: {},
  popularCMS: {},
  pageBySlug: {},
  cmsListCount: 0,
  showPerchaseModal : { cms_id: "", title: "", credit: "" }

};

export const getCmsListAsync = createAsyncThunk("CmsList", async (params: any) => {
  const response = await getCmsList(params.limit, params.page, params.language_id, params.filterValue, params.sport_id);
  return { params: params, data: response.data };
});

export const getCmsListByLangCodeAsync = createAsyncThunk("cmsListByLangCode", async (params: any) => {
  const response = await getCmsListByLangCode(params.limit, params.page);
  return { params: params, data: response.data };
});

export const getCmsByIdAsync = createAsyncThunk("CmsById", async (id: string) => {
  const response = await getCmsById(id);
  return response.data;
});

export const cmsAccessBuyAsync = createAsyncThunk("cmsAccessBuy", async (data: any) => {
  const response = await cmsAccessBuy(data);
  return response.data;
});

export const popularCMSAsync = createAsyncThunk("popularCMS", async (params: any) => {
  const response = await getPopularCMS(params.lang_id, params.user_id);
  return response.data;
});

export const pageBySlugAsync = createAsyncThunk("PageBySlug", async (slug: any) => {
  const response = await getPageBySlug(slug);
  return response.data;
});



export const cmsSlice = createSlice({
  name: "cms",
  initialState,

  reducers: {
    resetCmsAccessBuy: (state) => {
      state.cmsAccessBuy = {}
      state.showPerchaseModal = { cms_id: "", title: "", credit: "" }
    },
    resetCmsList: (state) => {
      state.cmsList = []
    },
    setShowPerchaseModal: (state, action: PayloadAction<any>) => {
      state.showPerchaseModal = action.payload
    }
  },

  extraReducers: (builder) => {

    // get CMS List
    builder.addCase(getCmsListAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(getCmsListAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.cmsListCount = action.payload.data.count;
      
      if (action.payload.params.page == 1) {
        state.cmsList = action.payload.data.data;
      } else {
        state.cmsList = [...state.cmsList, ...action.payload.data.data];
      }
    })
    builder.addCase(getCmsListAsync.rejected, (state) => {
      state.status = "failed";
    })

    // get CMS By ID
    builder.addCase(getCmsByIdAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(getCmsByIdAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.cmsDetail = action.payload.data;
    })
    builder.addCase(getCmsByIdAsync.rejected, (state) => {
      state.status = "failed";
    })

    // cms Access Buy
    builder.addCase(cmsAccessBuyAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(cmsAccessBuyAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.cmsAccessBuy = action.payload;
    })
    builder.addCase(cmsAccessBuyAsync.rejected, (state) => {
      state.status = "failed";
    })

    // popular CMSA
    builder.addCase(popularCMSAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(popularCMSAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.popularCMS = action.payload;
    })
    builder.addCase(popularCMSAsync.rejected, (state) => {
      state.status = "failed";
    })

    // Page By Slug
    builder.addCase(pageBySlugAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(pageBySlugAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.pageBySlug = action.payload;
    })
    builder.addCase(pageBySlugAsync.rejected, (state) => {
      state.status = "failed";
    })
  },
});

export const { resetCmsAccessBuy, resetCmsList, setShowPerchaseModal } = cmsSlice.actions;
export const cmsState = (state: any) => state.cms;
export default cmsSlice.reducer;
