import { toast } from 'react-toastify';
import i18n from '../i18n';

export const numberFormat = (number: number) => {
    return number ? Number(number).toLocaleString("en-CA", { style: "currency", currency: "CAD" ,maximumFractionDigits:0, minimumFractionDigits:0 }) : 0 ;
}

export const localNumber = (number: number) => {
    
    return number ? Number(number).toLocaleString() : 0 ;
}

export const preventNegativeValues = (e: any) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
}

export const showSuccessToast = (message: string) => {
    toast.success(i18n.t(message), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
}

export const showErrorToast = (message: string) => {
    toast.error(i18n.t(message), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
}

