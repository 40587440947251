import { Route, Router, Routes, useNavigate } from "react-router-dom";
import Home from "../pages/home";
import { useAppDispatch, useAppSelector } from "../stores/hooks";
import { Header } from "../component/header";
import { Footer } from "../component/footer";
import { Dashboard } from "../pages/dashboard";
import { MyAccount } from "../pages/myAccount";
import { useEffect, useState } from "react";
import { getLanguageAsync, getProfileAsync } from "../stores/appSlice";
import ContestDetail from "../component/contestDetail";
import BuyMediaCredit from "../component/buyMediaCredit";
import AppBody from "./body";
import { Store } from "../pages/store";
import { CmsDetail } from "../component/cmsDetail";
import Landing from "../pages/landing";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CloseContest from "../component/closeContest";
import { SponsorShip } from "../component/SponsorShip";
import ComingSoon from "../component/comingSoon";
import CommonPage from "../component/commonPage";
import StripeCallBack from './../pages/stripe';
import Checkout from "../pages/checkout"
import MyOrders from './../pages/myOrders';
import AchievementStore from "../component/achievementStore"
import { VideoDetail } from "../component/videoDetail"
import { PodcastDetail } from "../component/podcastDetail"

const AppRoute = () => {

    const { isUserLoggedIn, status, profile, appLoader } = useAppSelector(state => state.app);
    const [hasToken, setHasToken] = useState(false);
    const dispatchApp = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        checkLogin();
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [navigate]);

    const checkLogin = () => {
        let token = localStorage.getItem("token");
        if (token) {
            dispatchApp(getProfileAsync());
            setHasToken(true)
        }
        dispatchApp(getLanguageAsync());
    }
    if (hasToken && status == "loading" && !profile?.id) {
        return (<div className="appLoader">
            <img src="/images/ss-logo.png" />
        </div>)
    }

    return (
        <>{isUserLoggedIn ? <>
            {appLoader ? <div className="app-loadering"><img src="/images/Spinner.gif" /></div> : null}
            <Routes >
                <Route path="/" element={<AppBody><Dashboard /></AppBody>} />
                <Route path="contest/:status" element={<AppBody><Dashboard /></AppBody>} />
                <Route path="contest-detail/:id" element={<AppBody><ContestDetail /></AppBody>} />
                <Route path="cms-detail/:id" element={<AppBody><CmsDetail /></AppBody>} />
                <Route path="video-detail/:id" element={<AppBody><VideoDetail /></AppBody>} />
                <Route path="podcast-detail/:id" element={<AppBody><PodcastDetail /></AppBody>} />
                <Route path="buy-media-credit" element={<AppBody><BuyMediaCredit /></AppBody>} />
                <Route path="payment/:status" element={<AppBody><StripeCallBack /></AppBody>} />
                <Route path="payment/:status" element={<AppBody><StripeCallBack /></AppBody>} />
                <Route path="my-account" element={<AppBody><MyAccount /></AppBody>} />
                <Route path="store" element={<AppBody><Store /></AppBody>} />
                <Route path="checkout" element={<AppBody><Checkout /></AppBody>} />
                <Route path="sponsor" element={<AppBody><SponsorShip /></AppBody>} />
                <Route path="orders" element={<AppBody><MyOrders /></AppBody>} />
                <Route path="coming-soon" element={<AppBody><ComingSoon /></AppBody>} />
                <Route path="page/:slug" element={<AppBody><CommonPage /></AppBody>} />
                <Route path="achievement/shop/:product_id" element={<AppBody><AchievementStore /></AppBody>} />
            </Routes>
            <Footer />
        </> :
            <>
                {appLoader ? <div className="app-loadering"><img src="/images/Spinner.gif" /></div> : null}
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Home />} />
                    <Route path="store" element={<Store />} />
                    <Route path="sponsor" element={<SponsorShip />} />
                    <Route path="coming-soon" element={<ComingSoon />} />
                    <Route path="page/:slug" element={<CommonPage />} />
                </Routes>
                <Footer />
            </>}
        </>
    )
}

export default AppRoute;