import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getContest, getContestById } from "../api/appApi";

export interface AppStateInterface {
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  contest: any;
  contestById: any;
  contestRoundById: any;

}

const initialState: AppStateInterface = {
  status: "idle",
  contest: [],
  contestById: {},
  contestRoundById: {}
};

export const getContestAsync = createAsyncThunk("Contest", async (params: any) => {
  const response = await getContest(params.status, params.limit, params.page);
  return {
    params: params,
    data: response.data
  };
});

export const getContestByIdAsync = createAsyncThunk("ContestById", async (id: string) => {
  const response = await getContestById(id);
  return response.data;
});


export const contestSlice = createSlice({
  name: "contest",
  initialState,

  reducers: {
    setContest: (state) => {
      state.contest = []
    },
    resetContestById: (state) => {
      state.contestById = {}
    },
    setContestRoundById: (state, action) => {
      state.contestRoundById = action.payload
    }
  },

  extraReducers: (builder) => {

    // get Contest
    builder.addCase(getContestAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(getContestAsync.fulfilled, (state, action) => {
      state.status = "idle";
      if (action.payload.params.page == 1) {
        state.contest = action.payload.data.data;
      } else {
        state.contest = [...state.contest, ...action.payload.data.data];
      }
    })
    builder.addCase(getContestAsync.rejected, (state) => {
      state.status = "failed";
    })

    // get Contest By ID
    builder.addCase(getContestByIdAsync.pending, (state) => {
      state.status = "loading";
      state.contestById = {};
      state.contestRoundById = {}
    })
    builder.addCase(getContestByIdAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.contestById = action.payload.data;

      let round: any = {};
      if (action.payload.data.status === "Close") {
        round = action.payload.data.ContestRounds[action.payload.data.ContestRounds.length - 1];
      } else if (action.payload.data.ContestRounds.length >= 1) {
        round = action.payload.data.ContestRounds[0];
        for (let i = 0; i < action.payload.data.ContestRounds.length; i++) {
          if (action.payload.data.ContestRounds[i].status === "In progress") {
            round = action.payload.data.ContestRounds[i];
          } else if (round.status !== "In progress" && action.payload.data.ContestRounds[i].status === "Close") {
            round = action.payload.data.ContestRounds[i];
          }
        }
      }
      state.contestRoundById = round;
    })
    builder.addCase(getContestByIdAsync.rejected, (state) => {
      state.status = "failed";
    })


  },
});

export const { setContest, setContestRoundById, resetContestById } = contestSlice.actions;
export const contestState = (state: any) => state.contest;
export default contestSlice.reducer;
