import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { applySponsorship } from "../api/appApi";

export interface AppStateInterface {
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  Sponsorship: any;
}

const initialState: AppStateInterface = {
  status: "idle",
  Sponsorship: {},
};


export const applySponsorshipAsync = createAsyncThunk("applySponsorship", async (data: any) => {
  const response = await applySponsorship(data.data);
  if (response.data.error && response.data.message) {
    const error: any = {};
    for (var i = 0; i < response.data.error.length; i++) {
      error[response.data.error[i].path] = response.data.error[i].msg;
    }
    data.setErrors(error)
  }
  return response.data;
});


export const sponsorSlice = createSlice({
  name: "sponsor",
  initialState,

  reducers: {

    resetSponshorShip: (state) => {
      state.Sponsorship = {}
    }
  },

  extraReducers: (builder) => {

    // apply Sponsorship
    builder.addCase(applySponsorshipAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(applySponsorshipAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.Sponsorship = action.payload;
    })
    builder.addCase(applySponsorshipAsync.rejected, (state) => {
      state.status = "failed";
    })

  },
});

export const { resetSponshorShip } = sponsorSlice.actions;
export const sponsorState = (state: any) => state.sponsor;
export default sponsorSlice.reducer;
