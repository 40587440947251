import { useEffect, useState } from "react"
import {
  podcastState,
  setShowPerchaseModal,
  podcastAccessBuyAsync,
} from "../stores/podcastSlice"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import { appState, getProfileAsync } from "../stores/appSlice"
import { Link, useNavigate } from "react-router-dom"
import { PodcastAccessBuy } from "../api/appApi"

const PurchaseModalForPodcast = (props: any) => {
  const dispatchApp = useAppDispatch()
  const { t } = useTranslation()
  const { profile } = useAppSelector(appState)
  const navigate = useNavigate()
  const { podcastAccessBuy, showPerchaseModal } = useAppSelector(podcastState)
  const [isBuy, setIsBuy] = useState(false)

  const buyPodcast = () => {
    const data = {
      podcast_id: showPerchaseModal.podcast_id,
    }
    setIsBuy(true)
    dispatchApp(podcastAccessBuyAsync(data))
  }

  useEffect(() => {
    if (podcastAccessBuy.statusCode == 201 && isBuy) {
      navigate("/podcast-detail/" + showPerchaseModal.podcast_id)
      dispatchApp(getProfileAsync())
      setIsBuy(false)
      dispatchApp(setShowPerchaseModal({ video_id: "", title: "", credit: "" }))
    }
  }, [podcastAccessBuy, isBuy])

  return (
    <>
      {props.isShow && (
        <Modal
          show={showPerchaseModal.podcast_id != ""}
          onHide={() =>
            dispatchApp(
              setShowPerchaseModal({ podcast_id: "", title: "", credit: "" })
            )
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {Number(profile?.media_credit) >= Number(showPerchaseModal.credit)
                ? t("purchase-confirm")
                : t("INSUFFICIENT CREDITS")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {Number(profile?.media_credit) >=
            Number(showPerchaseModal.credit) ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Are you sure you would like to buy <b>title</b> article by using <b>credit</b> media credits?",
                    {
                      title: showPerchaseModal.title,
                      credit: showPerchaseModal.credit,
                    }
                  ),
                }}
              ></div>
            ) : (
              <div>
                {t("You do not have enough media credits to purchase")}{" "}
                <b>{showPerchaseModal.title}</b>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-orange box-shadow"
              onClick={() =>
                dispatchApp(
                  setShowPerchaseModal({
                    podcast_id: "",
                    title: "",
                    credit: "",
                  })
                )
              }
            >
              {t("Back")}
            </button>
            {Number(profile?.media_credit) >=
            Number(showPerchaseModal.credit) ? (
              <button
                onClick={() => buyPodcast()}
                className="btn btn-orange box-shadow"
              >
                {" "}
                {t("yes")}{" "}
              </button>
            ) : (
              <Link
                to="/buy-media-credit"
                onClick={() => {
                  dispatchApp(
                    setShowPerchaseModal({
                      podcast_id: "",
                      title: "",
                      credit: "",
                    })
                  )
                }}
                className="btn btn-orange box-shadow"
              >
                {" "}
                {t("buy_media_credits")}{" "}
              </Link>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}
export default PurchaseModalForPodcast
