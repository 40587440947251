import { Field, Form, Formik } from "formik"
import ErrorAlert from "../component/errorAlert"
import * as Yup from "yup"
import { useState } from "react"
import { useEffect } from "react"
import { t } from "i18next"
import { Link, useLocation } from "react-router-dom"
import { showErrorToast, showSuccessToast } from "../utils/commonFun"
import { createProductSession } from "../api/appApi"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { getShippingCostAsync } from "../stores/cartSlice"
import { CountryList } from "./countryList"

const Checkout = () => {
  const [checkoutData, setCheckoutData] = useState<any>({})
  const [cartProducts, setCartProducts] = useState<any>({})
  const [finalData, setFinalData] = useState<any>({})
  const [products, setProducts] = useState<any>([])
  const location = useLocation()
  const [totalCost, setTotalCost] = useState(0)
  const [optionalNote, setOptionalNote] = useState("")
  const [showPlaceOrderButton, setShowPlaceOrderButton] = useState(false)
  const [isAchievementCheckout, setIsAchievementCheckout] = useState(false)
  const [selectedVariants, setSelectedVariants] = useState<any>([])
  const dispatchApp = useAppDispatch()
  const { finalShippingCost } = useAppSelector((state: any) => state.cart)

  useEffect(() => {
    if (finalData.address_to) {
      if (isNaN(finalShippingCost)) {
        showErrorToast(t("Cannot place order due to error, please contact us."))
      } else {
        setShowPlaceOrderButton(true)
        showSuccessToast(`${t("Your address has been store successfully")}`)
      }
    }
  }, [finalShippingCost])

  useEffect(() => {
    setProducts(location.state.products)
    setTotalCost(location.state.totalCost + finalShippingCost)
    setSelectedVariants(location.state.variants)
    setIsAchievementCheckout(location.state.isAchievementCheckout)
  }, [finalShippingCost])

  let phoneRegx = /^[0-9]*$/g
  let emailRegx = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g

  useEffect(() => {
    if (localStorage.getItem("cardItem")) {
      var cardItem = localStorage.getItem("cardItem")
      cardItem = JSON.parse(cardItem)
      setCartProducts(cardItem)
    }
  }, [])

  const CheckoutSchema = Yup.object().shape({
    country: Yup.string().required(t("Country is a required field")),
    email: Yup.string()
      .matches(emailRegx, t("invalid-email"))
      .required(t("email-required")),
    first_name: Yup.string().required(t("firstname-required")),
    last_name: Yup.string().required(t("lastname-required")),
    address1: Yup.string().required(t("address1-required")),
    city: Yup.string().required(t("city-required")),
    zip: Yup.string()
      .required(t("zip-required"))
      .test(
        "len",
        t("Zipcode must be valid"),
        (val) => val.length === 6 || val.length === 5
      ),
    phone_number: Yup.string()
      .matches(phoneRegx, t("invalid-phone"))
      .required(t("phone-number-requried")),
    region: Yup.string().required(t("region-required")),
  })
  const onSubmitCheckoutForm = async (values: any) => {
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      phone: values.phone_number.toString(),
      country: values.country,
      region: values.region,
      address1: values.address1,
      addres2: values.address2,
      city: values.city,
      zip: values.zip.toString(),
      email: values.email,
    }

    var line_item: any = []

    if (isAchievementCheckout) {
      const _line_item = {
        product_id: products[0].id,
        quantity: products[0].quantities,
        variant_id: selectedVariants[0]?.finalVariant?.id,
        name: products[0].title,
        size: selectedVariants[0].size,
        color: selectedVariants[0].color,
        price: selectedVariants[0]?.finalVariant?.price / 100,
      }
      line_item.push(_line_item)
    }

    var checkoutData = {
      line_items: isAchievementCheckout ? line_item : cartProducts,
      shipping_method: 1,
      send_shipping_notification: false,
      address_to: data,
      external_id: "",
      label: "Safety",
    }

    dispatchApp(getShippingCostAsync(checkoutData))
    setTotalCost(location.state.totalCost + finalShippingCost)
    setFinalData(checkoutData)
  }

  const placeOrder = async () => {
    let data = { ...finalData, shipping_cost: finalShippingCost }
    var res = await createProductSession(data)

    if (res.data?.statusCode == 200 || res.data?.statusCode == 201) {
      localStorage.setItem("product_cs_id", res?.data?.data?.cs_id)
      window.open(res.data.data.payment_link, "_self")
    } else {
      showErrorToast(res.data.error)
    }
  }

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-12">
            <div className="position-relative contests-reg font-w300 font50 mb-3 text-center">
              {t("Checkout")}
            </div>
            <p className="text-center pb-3 mb-5 pt-1">
              {t(
                "Please enter your shipping address and ‘Calculate Shipping Cost’ before proceeding to ‘Place Order.’"
              )}
            </p>
            <div className="row">
              <div className="col-lg-7">
                <div className="checkout-accordion">
                  <div className="accordion" id="CheckoutAccordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingShipping">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseShipping"
                          aria-expanded="true"
                          aria-controls="collapseShipping"
                        >
                          {" "}
                          {t("Shipping address")}{" "}
                        </button>
                      </h2>
                      <div
                        id="collapseShipping"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingShipping"
                        data-bs-parent="#CheckoutAccordion"
                      >
                        <div className="accordion-body">
                          <Formik
                            initialValues={{
                              first_name: "",
                              last_name: "",
                              address1: "",
                              address2: "",
                              city: "",
                              region: "",
                              zip: "",
                              phone_number: "",
                              country: "",
                              email: "",
                            }}
                            validationSchema={CheckoutSchema}
                            onSubmit={onSubmitCheckoutForm}
                          >
                            {({ errors, touched }) => {
                              return (
                                <Form>
                                  <ErrorAlert
                                    errors={errors}
                                    touched={touched}
                                  />
                                  <div className="mb-3">
                                    <label className="form-label">
                                      {t("Country")}{" "}
                                      <span className="font16 text-error">
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      as="select"
                                      name="country"
                                      className={
                                        errors.country && touched.country
                                          ? "form-control error"
                                          : "form-control"
                                      }
                                    >
                                      <option value="">Select Country</option>
                                      {CountryList.map(
                                        (country: any, index: number) => {
                                          return (
                                            <option value={country?.value}>
                                              {country?.name}
                                            </option>
                                          )
                                        }
                                      )}
                                    </Field>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          {t("First Name")}{" "}
                                          <span className="font16 text-error">
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          name="first_name"
                                          type="text"
                                          className={
                                            errors.first_name &&
                                            touched.first_name
                                              ? "form-control error"
                                              : "form-control"
                                          }
                                          placeholder={t(
                                            "Enter your first name"
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          {t("Last Name")}{" "}
                                          <span className="font16 text-error">
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          name="last_name"
                                          type="text"
                                          className={
                                            errors.last_name &&
                                            touched.last_name
                                              ? "form-control error"
                                              : "form-control"
                                          }
                                          placeholder={t(
                                            "Enter your last name"
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      {t("email")}{" "}
                                      <span className="font16 text-error">
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="email"
                                      className={
                                        errors.email && touched.email
                                          ? "form-control error"
                                          : "form-control"
                                      }
                                      placeholder={t("enter_your_email_id")}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      {t("Address 1")}{" "}
                                      <span className="font16 text-error">
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="address1"
                                      className={
                                        errors.address1 && touched.address1
                                          ? "form-control error"
                                          : "form-control"
                                      }
                                      placeholder={t("Street address 1")}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      {t("Address 2")}{" "}
                                    </label>
                                    <Field
                                      type="text"
                                      name="address2"
                                      className={
                                        errors.address2 && touched.address2
                                          ? "form-control error"
                                          : "form-control"
                                      }
                                      placeholder={t("Street address 2")}
                                    />
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          {t("Town / City")}{" "}
                                          <span className="font16 text-error">
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="city"
                                          className={
                                            errors.city && touched.city
                                              ? "form-control error"
                                              : "form-control"
                                          }
                                          placeholder={t(
                                            "Enter your City name"
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          {t("Region")}{" "}
                                          <span className="font16 text-error">
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="region"
                                          className={
                                            errors.region && touched.region
                                              ? "form-control error"
                                              : "form-control"
                                          }
                                          placeholder={t(
                                            "Enter your region name"
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          {t("Postcode / Zip")}{" "}
                                          <span className="font16 text-error">
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="zip"
                                          className={
                                            errors.zip && touched.zip
                                              ? "form-control error"
                                              : "form-control"
                                          }
                                          placeholder={t("Enter your Postcode")}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          {t("Phone number")}{" "}
                                          <span className="font16 text-error">
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="number"
                                          name="phone_number"
                                          className={
                                            errors.phone_number &&
                                            touched.phone_number
                                              ? "form-control error"
                                              : "form-control"
                                          }
                                          placeholder={t(
                                            "Enter your Phone number"
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-4 mb-3 text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-orange view-more-btn"
                                    >
                                      {t("Calculate Shipping Cost")}
                                    </button>
                                  </div>
                                </Form>
                              )
                            }}
                          </Formik>
                        </div>
                      </div>
                    </div>
                    {/* <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="headingPaymentMethod"
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsePaymentMethod"
                          aria-expanded="false"
                          aria-controls="collapsePaymentMethod"
                        >
                          {" "}
                          {t("Payment method")}{" "}
                        </button>
                      </h2>
                      <div
                        id="collapsePaymentMethod"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingPaymentMethod"
                        data-bs-parent="#CheckoutAccordion"
                      >
                        <div className="accordion-body">
                          <div className="mb-3">
                            <label className="form-label">
                              {t("Accepted Cards:")}
                            </label>
                            <div>
                              <img
                                src="images/visa-card.png"
                                alt="visa-card"
                                className="me-2 mb-1"
                              />
                              <img
                                src="images/master-card.png"
                                alt="master-card"
                                className="me-2 mb-1"
                              />
                              <img
                                src="images/discover-card.png"
                                alt="visa-card"
                                className="me-2 mb-1"
                              />
                              <img
                                src="images/amex-card.png"
                                alt="visa-card"
                                className="me-2 mb-1"
                              />
                              <img
                                src="images/hsa-card.png"
                                alt="visa-card"
                                className="me-2 mb-1"
                              />
                              <img
                                src="images/fsa-card.png"
                                alt="visa-card"
                                className="me-2 mb-1"
                              />
                            </div>
                          </div>
                          <div className="mb-3 relative">
                            <label className="form-label">
                              {t("Credit card Number")}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="1234  -  4567  -  1234  -  5678"
                            />
                            <img
                              src="images/master-card-icon.png"
                              alt="master-card-icon"
                              className="form-img"
                            />
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  {t("Card Expiration")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="09"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">{t("CVV")}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="018"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-3 relative">
                            <label className="form-label">
                              {t("Billing Zip Code")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="1234568"
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="white-box p-4">
                  <div className="font18 font-w600 text-black mb-3">
                    {t("Additional information")}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      {t("Order notes (optional)")}
                    </label>
                    <textarea
                      className="form-control"
                      value={optionalNote}
                      placeholder={t(
                        "Notes about your order, e.g. special notes for delivery."
                      )}
                      onChange={(e) => {
                        setOptionalNote(e.target.value)
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="white-box cartbox mt-4">
                  <div className="p-4 text-center text-orange font26 font-w500">
                    {t("Your order")}
                  </div>
                  <div className="cart-summry">
                    <img
                      src="/images/icons/cart.svg"
                      alt="cart"
                      className="me-2"
                    />
                    {t("Order Summary")}
                  </div>
                  <div className="pe-4 ps-4 pt-4">
                    <div className="row align-items-center">
                      <div className="col-sm-8">
                        <div className="font18 font-w500">{t("Item name")}</div>
                      </div>
                      <div className="col-sm-4">
                        <div className="font18 font-w500 text-end">
                          {t("Price")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dassed-line"></div>
                  {isAchievementCheckout
                    ? products.map((product: any, index: number) => {
                        return (
                          <>
                            <div className="pe-4 ps-4 py-3">
                              <div className="row align-items-center">
                                <div className="col-sm-8">
                                  <div className="font17">
                                    {product?.title}
                                    <strong>X {product?.quantities}</strong>
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div className="font22 text-end">
                                    {selectedVariants[index]?.finalVariant
                                      ?.price / 100}{" "}
                                    CAD
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="dassed-line"></div>
                          </>
                        )
                      })
                    : products?.map((product: any, index: number) => {
                        return (
                          <>
                            <div className="pe-4 ps-4 py-3">
                              <div className="row align-items-center">
                                <div className="col-sm-8">
                                  <div className="font17">
                                    {product?.title}
                                    <strong>
                                      X {cartProducts[index]?.quantity}
                                    </strong>
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div className="font22 text-end">
                                    {selectedVariants[index]?.price / 100} CAD
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="dassed-line"></div>
                          </>
                        )
                      })}
                  <div className="pe-4 ps-4 py-3">
                    <div className="row align-items-center">
                      <div className="col-sm-8">
                        <div className="font17">{t("Shipping")}</div>
                      </div>
                      <div className="col-sm-4">
                        <div className="font22 text-end">
                          {finalShippingCost} CAD
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dassed-line"></div>
                  <div className="pe-4 ps-4 py-3">
                    <div className="row align-items-center">
                      <div className="col-sm-8">
                        <div className="font17">{t("Cart Subtotal")}</div>
                      </div>
                      <div className="col-sm-4">
                        <div className="font22 text-end">
                          {totalCost.toFixed(2)} CAD
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dassed-line"></div>
                  <div className="pe-4 ps-4 pt-2 pb-4">
                    <div className="row align-items-center">
                      <div className="col-sm-8">
                        <div className="font18 font-w500">{t("total")}</div>
                      </div>
                      <div className="col-sm-4">
                        <div className="font26 font-w600 text-orange text-end">
                          {totalCost.toFixed(2)} CAD
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 mb-3 text-center">
                  {showPlaceOrderButton && (
                    <button
                      className="btn btn-orange view-more-btn"
                      onClick={() => {
                        if (finalData?.line_items) {
                          placeOrder()
                        } else {
                          showErrorToast(
                            `${t(
                              "Please fill the address form below and calculate shipping cost before proceeding"
                            )}`
                          )
                        }
                      }}
                    >
                      {t("Place Order")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Checkout
