import { useEffect, useState } from "react"
import { Formik, Form, Field } from "formik"
import { useNavigate } from "react-router-dom"
import { signin } from "../api/appApi"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { getProfileAsync, reSetPassword, setEmail } from "../stores/appSlice"
import { useTranslation } from "react-i18next"
import ErrorAlert from "./errorAlert"
import { useAppDispatch } from "../stores/hooks"
import { showErrorToast } from "../utils/commonFun"

export const Signin = (props: any) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [status, setStatus] = useState("")
  const [showPass, setShowPass] = useState(false)
  const dispatchApp = useAppDispatch()

  let passRegx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/g
  let emailRegx = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g
  const SigninSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegx, t("invalid-email"))
      .required(t("email-required")),
    password: Yup.string()
      .matches(passRegx, t("invalid-password"))
      .required(t("password-required")),
  })

  const onSubmitSigninForm = async (values: any, actions: any) => {
    setStatus("loading")
    const data = {
      email: values.email,
      password: values.password,
    }
    const res = await signin(data)
    if (res.status === 200) {
      if (res?.data?.data?.access_token) {
        localStorage.setItem("token", res?.data?.data?.access_token)
        setStatus("")
        props.setVisibleModel("")
        dispatchApp(getProfileAsync())
      } else {
        dispatch(setEmail(values.email))
        props.setVisibleModel("verify-otp")
        setStatus("")
      }
    } else if (res.data.error && res.data.message) {
      const error: any = {}
      for (var i = 0; i < res.data.error.length; i++) {
        error[res.data.error[i].path] = res.data.error[i].msg
      }
      actions.setErrors(error)
      setStatus("")
    } else if (res.status == 404) {
      showErrorToast(res.data.message)
      setStatus("")
    } else if (res.status == 400) {
      showErrorToast(res.data.message)
      setStatus("")
    } else {
      showErrorToast(t("Something wrong."))
      setStatus("")
    }
  }

  useEffect(() => {
    dispatch(reSetPassword())
  }, [])

  return (
    <>
      <div className="ss-login-bg"></div>
      <div id="Login" className="ss-login">
        <div>
          <a
            onClick={() => {
              props.setVisibleModel("")
            }}
          >
            <img src="/images/icons/login-close.svg" width="35" alt="close" />
          </a>
        </div>
        <div className="mt-5">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="font26 font-w500 text-orange mb-2">
                  {t("log_in")}
                </div>
                <div>{t("login_info")}</div>
                <div className="ss-hrline"></div>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={SigninSchema}
                  onSubmit={onSubmitSigninForm}
                >
                  {({ errors, touched }) => {
                    return (
                      <Form>
                        <ErrorAlert errors={errors} touched={touched} />
                        <div className=" relative">
                          <label className="form-label">{t("email")}</label>
                          <Field
                            name="email"
                            type="email"
                            className={
                              errors.email && touched.email
                                ? "form-control error"
                                : "form-control"
                            }
                            placeholder={t("enter_your_email_id")}
                          />
                          <img
                            src="/images/icons/email.svg"
                            alt="email"
                            className="ss-form-email"
                          />
                        </div>

                        <div className="mt-3 relative">
                          <label className="form-label">{t("password")}</label>
                          <Field
                            name="password"
                            type={showPass ? "text" : "password"}
                            className={
                              errors.password && touched.password
                                ? "form-control error"
                                : "form-control"
                            }
                            placeholder="● ● ● ● ● ●"
                          />
                          {showPass ? (
                            <img
                              onClick={() => {
                                setShowPass(!showPass)
                              }}
                              src="/images/icons/eye.svg"
                              alt="eye"
                              className="ss-form-eye-close"
                            />
                          ) : (
                            <img
                              onClick={() => {
                                setShowPass(!showPass)
                              }}
                              src="/images/icons/eye-close.svg"
                              alt="email"
                              className="ss-form-eye-close"
                            />
                          )}
                        </div>

                        {/* <div className="mt-3">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-check">
                                                                <input type="checkbox" className="form-check-input" checked />
                                                                <label className="form-check-label">{t('remember_me')}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 text-end">
                                                            <a href="#" className="link-orange font-w500" onClick={() => props.setVisibleModel("forgot-password")}>{t('forgot_login_details')}</a>
                                                        </div>
                                                    </div>
                                                </div> */}
                        <button
                          type="submit"
                          className="btn btn-orange d-block w-100 radius25 mt-3"
                        >
                          {status == "loading" ? t("loading") : t("log_in")}{" "}
                        </button>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="font-w500 mt-4">
                              {t("dont_have_an_account")}{" "}
                              <a
                                href="#"
                                className="link-orange"
                                onClick={() => {
                                  props.setVisibleModel("signup")
                                }}
                              >
                                {" "}
                                {t("sign_up")}
                              </a>
                            </div>
                          </div>
                          <div className="col-md-6 text-end mt-4">
                            <a
                              href="#"
                              className="link-orange font-w500"
                              onClick={() =>
                                props.setVisibleModel("forgot-password")
                              }
                            >
                              {t("forgot_login_details")}
                            </a>
                          </div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
