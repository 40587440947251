import { useEffect } from "react"
import {
  cmsAccessBuyAsync,
  cmsState,
  resetCmsAccessBuy,
  setShowPerchaseModal,
} from "../stores/cmsSlice"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import { appState, getProfileAsync } from "../stores/appSlice"
import { Link, useNavigate } from "react-router-dom"

const PurchaseModal = () => {
  const dispatchApp = useAppDispatch()
  const { t } = useTranslation()
  const { cmsAccessBuy, showPerchaseModal } = useAppSelector(cmsState)
  const { profile } = useAppSelector(appState)
  const navigate = useNavigate()

  const buyCms = () => {
    const data = {
      cms_id: showPerchaseModal.cms_id,
    }
    dispatchApp(cmsAccessBuyAsync(data))
  }
  useEffect(() => {
    if (cmsAccessBuy.statusCode == 201) {
      navigate("/cms-detail/" + showPerchaseModal.cms_id)
      dispatchApp(resetCmsAccessBuy())
      dispatchApp(getProfileAsync())
    }
  }, [cmsAccessBuy])

  return (
    <>
      <Modal
        show={showPerchaseModal.cms_id != ""}
        onHide={() =>
          dispatchApp(
            setShowPerchaseModal({ cms_id: "", title: "", credit: "" })
          )
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {Number(profile?.media_credit) >= Number(showPerchaseModal.credit)
              ? t("purchase-confirm")
              : t("INSUFFICIENT CREDITS")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {Number(profile?.media_credit) >= Number(showPerchaseModal.credit) ? (
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  "Are you sure you would like to buy <b>title</b> article by using <b>credit</b> media credits?",
                  {
                    title: showPerchaseModal.title,
                    credit: showPerchaseModal.credit,
                  }
                ),
              }}
            ></div>
          ) : (
            <div>
              {t("You do not have enough media credits to purchase")}{" "}
              <b>{showPerchaseModal.title}</b>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-orange box-shadow"
            onClick={() =>
              dispatchApp(
                setShowPerchaseModal({ cms_id: "", title: "", credit: "" })
              )
            }
          >
            {t("Back")}
          </button>
          {Number(profile?.media_credit) >= Number(showPerchaseModal.credit) ? (
            <button
              onClick={() => buyCms()}
              className="btn btn-orange box-shadow"
            >
              {" "}
              {t("yes")}{" "}
            </button>
          ) : (
            <Link
              to="/buy-media-credit"
              onClick={() => {
                dispatchApp(
                  setShowPerchaseModal({ cms_id: "", title: "", credit: "" })
                )
              }}
              className="btn btn-orange box-shadow"
            >
              {" "}
              {t("buy_media_credits")}{" "}
            </Link>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default PurchaseModal
