import { useEffect, useState } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../stores/hooks"
import {
  applySponsorshipAsync,
  resetSponshorShip,
  sponsorState,
} from "../stores/sponsorSlice"
import ErrorAlert from "./errorAlert"
import { preventNegativeValues, showSuccessToast } from "../utils/commonFun"
import { Tooltip } from "react-tooltip"
import { Modal } from "react-bootstrap"

export const SponsorShip = (props?: any) => {
  const { Sponsorship, status } = useSelector(sponsorState)
  const dispatchApp = useAppDispatch()
  const [serverErrorMessage, setServerErrorMessage] = useState("")
  const [showModel, setShowModel] = useState(false)
  const { t } = useTranslation()

  let emailRegx = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g
  let webUrlRegx = /(https?:\/\/)?(www\.)?[a-z0-9-]+\.([a-z]{2,3})/g
  let phoneRegx = /^[0-9]*$/g

  const SponsorShipSchema = Yup.object().shape({
    company_name: Yup.string().required(t("company-name-requried")),
    email: Yup.string()
      .matches(emailRegx, t("sponsor-invalid-email"))
      .required(t("sponsor-email-required")),
    contact_name: Yup.string().required(t("contact-name-requried")),
    website: Yup.string()
      .matches(webUrlRegx, t("invalid-website"))
      .required(t("website-requried")),
    // website: Yup.string().required(t('website-requried')),
    phone_number: Yup.string()
      .matches(phoneRegx, t("invalid-phone"))
      .required(t("phone-number-requried")),
    type_of_sponsor: Yup.string().required(t("type-of-sponsor-requried")),
    company_offering: Yup.string().required(t("company-offering-requried")),
    budget: Yup.number().required(t("budget-requried")),
  })

  const onSubmitSignUpForm = async (values: any, actions: any) => {
    setServerErrorMessage("")
    const data = {
      company_name: values.company_name,
      contact_name: values.contact_name,
      phone_number: values.phone_number,
      type_of_sponsor: values.type_of_sponsor,
      company_offering: values.company_offering,
      website: values.website,
      email: values.email,
      budget: values.budget,
      status: "Pending",
    }
    dispatchApp(applySponsorshipAsync({ data, setErrors: actions.setErrors }))
  }

  useEffect(() => {
    if (Sponsorship?.statusCode === 200) {
      setShowModel(true)
      dispatchApp(resetSponshorShip())
      // showSuccessToast(Sponsorship?.message);
      // props?.setVisibleModel('');
    }
  }, [Sponsorship])

  return (
    <>
      {/* <div className="ss-login-bg"></div>
    <div id="Signup" className="ss-login">

      <div>
        <a onClick={() => { props?.setVisibleModel('') }} > <img src="/images/icons/login-close.svg" width="35" alt="close" /> </a>
      </div> */}

      <div className="mt-5">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="col-sm-12 text-center">
                <div className="position-relative contests-reg font-w300 font50 pb-3 mb-3 text-center">
                  {t("Sponsorships")}
                </div>
                <div>
                  {t(
                    "Would you or your business like to advertise to Safety Survivor users?"
                  )}
                  <a href="#!" data-tooltip-id="my-tooltip">
                    <img
                      src="/images/icons/info.svg"
                      alt="info"
                      className="ms-2"
                    />
                  </a>
                  <Tooltip
                    id="my-tooltip"
                    closeOnScroll
                    openOnClick
                    opacity={100}
                  >
                    <div>
                      <img
                        height={350}
                        width={1350}
                        src="/images/sponsor_comp.png"
                        alt=""
                      />
                    </div>
                  </Tooltip>
                </div>
                <div>{t("please_fill_out_all_applicable_fields_below")}</div>
                <div className="ss-hrline"></div>
              </div>

              {Sponsorship?.statusCode === 200 ? (
                <div>
                  <div className="font26 font-w500">
                    {"Thank you your response has been submitted"}
                  </div>
                </div>
              ) : (
                <div>
                  <Formik
                    initialValues={{
                      company_name: "",
                      email: "",
                      contact_name: "",
                      website: "",
                      phone_number: "",
                      type_of_sponsor: "",
                      company_offering: "",
                      budget: "",
                    }}
                    validationSchema={SponsorShipSchema}
                    onSubmit={onSubmitSignUpForm}
                  >
                    {({ errors, touched }) => {
                      return (
                        <Form>
                          {serverErrorMessage ? (
                            <div className="alert alert-danger">
                              {serverErrorMessage}
                            </div>
                          ) : null}
                          <ErrorAlert errors={errors} touched={touched} />
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  {t("company_name")}
                                </label>
                                <Field
                                  name="company_name"
                                  type="text"
                                  className={
                                    errors.company_name && touched.company_name
                                      ? "form-control error"
                                      : "form-control"
                                  }
                                  placeholder={t("company_name")}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  {t("contact_email_address")}
                                </label>
                                <Field
                                  name="email"
                                  type="email"
                                  className={
                                    errors.email && touched.email
                                      ? "form-control error"
                                      : "form-control"
                                  }
                                  placeholder={t("email_address")}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  {t("company_contact_name")}
                                </label>
                                <Field
                                  name="contact_name"
                                  type="text"
                                  className={
                                    errors.contact_name && touched.contact_name
                                      ? "form-control error"
                                      : "form-control"
                                  }
                                  placeholder={t("contact_name")}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  {t("website_link")}
                                </label>
                                <Field
                                  name="website"
                                  type="text"
                                  className={
                                    errors.website && touched.website
                                      ? "form-control error"
                                      : "form-control"
                                  }
                                  placeholder={t("placeholder_website_link")}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  {t("contact_phone_number")}
                                </label>
                                <Field
                                  name="phone_number"
                                  type="text"
                                  className={
                                    errors.phone_number && touched.phone_number
                                      ? "form-control error"
                                      : "form-control"
                                  }
                                  placeholder={t("phone_number")}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  {t(
                                    "type_of_content_you_dont_like_to_sponsor"
                                  )}
                                </label>
                                <Field
                                  name="type_of_sponsor"
                                  className={
                                    errors.type_of_sponsor &&
                                    touched.type_of_sponsor
                                      ? "form-control error"
                                      : "form-control"
                                  }
                                  as="select"
                                >
                                  <option value="">Select</option>
                                  <option value="Media content">
                                    Media Content
                                  </option>
                                  <option value="Contests">Contests</option>
                                  <option value="Both">Both</option>
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div>
                              <div className="mb-3">
                                <label className="form-label">
                                  {t("company_offerings")}
                                </label>
                                <Field
                                  name="company_offering"
                                  component="textarea"
                                  rows="5"
                                  className={
                                    errors.company_offering &&
                                    touched.company_offering
                                      ? "form-control error"
                                      : "form-control"
                                  }
                                  placeholder={t(
                                    "please_briefly_explain_what_your_company_offers"
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div>
                              <div className="mb-3">
                                <label className="form-label">
                                  {t("budget_for_sponsorship")}
                                </label>
                                <Field
                                  name="budget"
                                  onKeyDown={preventNegativeValues}
                                  onWheel={(event: any) =>
                                    event.currentTarget.blur()
                                  }
                                  type="number"
                                  className={
                                    errors.budget && touched.budget
                                      ? "form-control error"
                                      : "form-control"
                                  }
                                  placeholder={t("budget")}
                                />
                              </div>
                            </div>
                          </div>

                          <button
                            type="submit"
                            className="btn btn-orange d-block w-100 radius25 mt-3"
                          >
                            {" "}
                            {status == "loading"
                              ? t("loading")
                              : t("submit")}{" "}
                          </button>

                          <div className="imp-note">
                            <div className="mt-5 mb-3">
                              <strong> {t("important notes")} </strong>
                            </div>
                            <ul>
                              <li>
                                {" "}
                                {t(
                                  "Safety Survivor is very selective when choosing our affiliates and business partners."
                                )}{" "}
                              </li>
                              <li>
                                {" "}
                                {t(
                                  "We will only make contact with potential sponsors if we are interested in pursuing a business relationship, after conducting  internal discussions."
                                )}{" "}
                              </li>
                              <li>
                                {" "}
                                {t(
                                  "Please allow 2-3 business days for us to review your candidacy."
                                )}{" "}
                              </li>
                            </ul>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <Modal show={showModel} onHide={() => setShowModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Application Received")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            "Thank you for your interest in pursuing a business relationship with Safety Survivor. We will review your candidacy shortly!"
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-orange box-shadow"
            onClick={() => {
              setShowModel(false)
            }}
          >
            {" "}
            {t("Ok")}{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
