import React, { useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import {
  type StripeElementsOptions,
} from '@stripe/stripe-js';

import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Link, useNavigate } from 'react-router-dom';
import { buyMediaCredit } from '../api/appApi';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getProfileAsync, setAppLoader } from '../stores/appSlice';
import { useAppDispatch } from '../stores/hooks';
import { showErrorToast } from '../utils/commonFun';
import Config from '../utils/config';
const stripePromise = loadStripe(Config.STRIPE_KEY);

const CheckoutForm = ({ setTab, amount, mediaCredit, contests }: { setTab: Function, amount: number, mediaCredit: number, contests: any[] }) => {

  const stripe = useStripe();

  const elements = useElements();
  const navigation = useNavigate()
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);
  const [showWarningModel, setShowWarningModel] = useState(false);

  const handleError = (error: any) => {
    setLoading(false);
    setErrorMessage(error.message);
  }

  const handelSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe) {
      return;
    }

    dispatch(setAppLoader(true));

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      dispatch(setAppLoader(false));
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          name: 'Jenny Rosen',
        }
      }
    });

    if (error) {
      handleError(error);
      dispatch(setAppLoader(false));
      return;
    }

    const _contests: any[] = [];
    for (let i = 0; i < contests.length; i++) {
      if (contests[i] && contests[i]?.contest_id) {
        _contests.push(contests[i])
      }
    }

    const res = await buyMediaCredit({
      token: paymentMethod.id,
      credit: mediaCredit,
      contests: _contests,
      payment_type: "card",
    })
    if (res.status == 200) {
      dispatch(getProfileAsync());
      setShowWarningModel(true);
      setLoading(false);
    } else {
      showErrorToast(t("Purchase Abandoned, Please try again."))
      setLoading(false);
    }
  }
  return (
    <form onSubmit={handelSubmit}>
        {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : null}
      <div className="white-box p-4">
        <PaymentElement />
      </div>
      <div className="text-center my-5">
        <div className="row justify-content-center">
          <div className="col-6 col-sm-4 col-md-3"><button onClick={() => { setTab(1) }} className="btn btn-border-orange btn-shadow w-100">{t("Back")}</button>
          </div>
          <div className="col-6 col-sm-4 col-md-3">
            <button type="submit" className="btn btn-orange btn-shadow w-100">{t("Buy")}</button>
          </div>
        </div>
      </div>

      <Modal show={showWarningModel} onHide={() => setShowWarningModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Thank you")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Thank you for your payment, it has been received. Your account will be credit with you media credits immediately! Enjoy our site")}
        </Modal.Body>
        <Modal.Footer>

          <button onClick={() => {
            navigation("/");
          }} className="btn btn-orange box-shadow"> {t("Ok")} </button>
        </Modal.Footer>

      </Modal>
    </form>

  );
};

const PaymentForm = ({ setTab, amount, mediaCredit, contests }: { setTab: Function, amount: number, mediaCredit: number, contests: [] }) => {

  const options: StripeElementsOptions = {
    mode: 'payment',
    currency: 'cad',
    amount: amount,
    paymentMethodCreation: 'manual',
    appearance: {
    },
  };

  // return <div></div>;
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm setTab={setTab} amount={amount} mediaCredit={mediaCredit} contests={contests} />
    </Elements>
  );
};

export default PaymentForm;
