import { useEffect, useState } from "react"
import {
  videoState,
  getVideoListAsync,
  setShowPerchaseModal,
  resetVideoList,
} from "../stores/videoSlice"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { useTranslation } from "react-i18next"
import { Button, Modal } from "react-bootstrap"
import { appState, getProfileAsync } from "../stores/appSlice"
import { Link, useNavigate } from "react-router-dom"
import { localNumber } from "../utils/commonFun"
import { getProfile } from "../api/appApi"
import i18n from "../i18n"
import { generateFileUrl } from "../utils/AzureStorageBlob"
import PurchaseModalForVideo from "./purchaseModalForVideo"

const Video = (props: any) => {
  // return (
  //   <div className="resp-tabs-container hor_1">
  //     <div className="artiletab">
  //       <div className="aritile-listview" id="aritile-listview">
  //         <div className="row align-content-center">
  //           <div className="col-md-12">
  //             <div className="ariclebox">
  //               <div className="article-title">{"Coming Soon"}</div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )

  const dispatchApp = useAppDispatch()
  const { t } = useTranslation()
  const { languages, homeData } = useAppSelector(appState)
  const { status, videoList, videoListCount, showPerchaseModal } =
    useAppSelector(videoState)
  const [limit, setLimit] = useState(3)
  const [page, setPage] = useState(1)
  const { profile } = useAppSelector(appState)
  const navigate = useNavigate()

  useEffect(() => {
    getData()
  }, [page, limit, i18n.language, props.filter, props.sportId])

  useEffect(() => {
    if (page == 1) {
      getData()
    } else {
      setPage(1)
    }
    dispatchApp(resetVideoList())
  }, [i18n.language, props.filter, props.sportId])

  const getData = async () => {
    let _language_id = ""
    const found = await languages?.data?.find(
      (element: any) => element.language_code == i18n.language
    )
    if (found?.language_id) {
      _language_id = found?.language_id
    }

    let params = {
      limit: limit,
      page: page,
      language_id: _language_id,
      filterValue: props.filter,
      sport_id: props.sportId,
    }
    await dispatchApp(getVideoListAsync(params))
  }

  const viewMoreBtn = () => {
    return (
      videoListCount > page * limit && (
        <div className="gridviewbtn mb-4">
          <a
            onClick={() => {
              setPage(page + 1)
            }}
            className="btn btn-orange box-shadow view-more-btn"
          >
            {status == "loading" ? t("loading") : t("view-more")}
          </a>
        </div>
      )
    )
  }

  return (
    <div className="resp-tabs-container hor_1">
      <div className="artiletab">
        {props?.viewType == "grid" ? (
          <div className="aritile-listview" id="aritile-listview">
            <div className="row align-content-center">
              {videoList?.map((item: any, index: number) => {
                return (
                  <div key={`aricle_ ${index}`} className="col-md-4 mb-5">
                    <div className="ariclebox">
                      <img
                        src={generateFileUrl(item?.Sport?.logo)}
                        alt="article"
                      />
                      <div className="article-title">{item?.title}</div>
                      <div className="ariclebyname">
                        {t("by")} {item?.author}
                      </div>
                      <div>{item?.short_description}</div>
                      <div
                        className="photos-credit pointer"
                        onClick={() => {
                          if (item?.purchased) {
                            navigate("/video-detail/" + item.video_id)
                          } else {
                            dispatchApp(
                              setShowPerchaseModal({
                                video_id: item.video_id,
                                title: item?.title,
                                credit: item?.credit,
                              })
                            )
                          }
                        }}
                      >
                        <span className="photos-buy">
                          {item?.purchased ? (
                            t("View")
                          ) : (
                            <>
                              <span>
                                <img
                                  src="/images/icons/camera.svg"
                                  alt="camera"
                                />
                              </span>
                              {localNumber(item?.credit)}
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            {viewMoreBtn()}
          </div>
        ) : (
          videoList?.length > 0 && (
            <>
              <div className="aritile-gridview" id="aritile-gridview">
                {videoList?.map((item: any, index: number) => {
                  return (
                    <div
                      key={`aricle_gridview_ ${index}`}
                      className="ariclebox"
                    >
                      <div className="row align-items-center">
                        <div className="col-lg-1">
                          <div className="articl-icon">
                            <img
                              src={generateFileUrl(item?.Sport?.logo)}
                              alt="article"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="article-title">{item?.title}</div>
                          <div className="ariclebyname">
                            {t("by")} {item?.author_name}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="info">{item?.short_description}</div>
                        </div>
                        <div className="col-lg-2">
                          <div
                            className="photos-credit pointer position-static"
                            onClick={() => {
                              item?.purchased
                                ? navigate("/video-detail/" + item.video_id)
                                : dispatchApp(
                                    setShowPerchaseModal({
                                      video_id: item.video_id,
                                      title: item?.title,
                                      credit: item?.credit,
                                    })
                                  )
                            }}
                          >
                            <span className="photos-buy">
                              {item?.purchased ? (
                                t("View")
                              ) : (
                                <>
                                  <span>
                                    <img
                                      src="/images/icons/camera.svg"
                                      alt="camera"
                                    />
                                  </span>
                                  {localNumber(item?.credit)}
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              {viewMoreBtn()}
            </>
          )
        )}

        {videoList.length == 0 && status != "loading" && (
          <div className="aritile-listview" id="aritile-listview">
            <div className="row align-content-center">
              <div className="text-center font-w300 font22 m-4">
                {t("No Videos Found")}
              </div>
            </div>
          </div>
        )}
      </div>

      <PurchaseModalForVideo
        isShow={showPerchaseModal?.video_id != "" ? true : false}
      />
    </div>
  )
}
export default Video
