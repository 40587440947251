import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useAppDispatch, useAppSelector } from '../stores/hooks';
import { appState, getProfileAsync, resendOTPAsync } from '../stores/appSlice';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { verifyOtp } from '../api/appApi';
import ErrorAlert from './errorAlert';
import { showErrorToast, showSuccessToast } from '../utils/commonFun';

export const VerifyOtp = (props: any) => {
    const dispatchApp = useAppDispatch();
    const { email, resendOtp } = useAppSelector(appState);
    const [status, setStatus] = useState("");
    const { t } = useTranslation();

    const onSubmitVerifyOtpForm = async (values: any, actions: any) => {

        setStatus("loading");
        const data = {
            email: email,
            email_otp: values.otp,
        }
        const res = await verifyOtp(data);
        if (res.status === 200) {
            if (res.data.data.access_token) {
                localStorage.setItem('token', res.data.data.access_token);
                props.setVisibleModel("")
                dispatchApp(getProfileAsync());
                setStatus("");
            } else {
                showErrorToast(t("Something wrong."));
                setStatus("");
            }
        } else if (res.status === 400) {
            showErrorToast(res.data.message);
            setStatus("");
        } else {
            showErrorToast(t("Please enter correct verification code."));
            setStatus("");
        }
    }

    const VerifyOtpSchema = Yup.object().shape({
        otp: Yup.string().matches(/^[0-9]{6}$/, t("invalid-otp")).required(t("otp-required")),
    });

    const resendOTP = () => {
        const data = {
            email: email
        }
        dispatchApp(resendOTPAsync(data))
    }

    useEffect(() => {
        if (resendOtp.statusCode == 200) {
            showSuccessToast(resendOtp.message);
        }
    }, [resendOtp])

    return (
        <>
            <div className="ss-login-bg"></div>
            <div id="Login" className="ss-login">
                <div><a onClick={() => { props.setVisibleModel("") }}><img src="/images/icons/login-close.svg" width="35" alt="close" /></a></div>
                <div className="mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="font26 font-w500 text-orange mb-2">{t('verify_otp')}</div>
                                <div>{t('verification_info')}</div>
                                <div className="ss-hrline"></div>
                                <Formik
                                    initialValues={{ otp: "" }}
                                    onSubmit={onSubmitVerifyOtpForm}
                                    validationSchema={VerifyOtpSchema}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <ErrorAlert errors={errors} touched={touched} />
                                            <div className="mb-3 relative">
                                                <label className="form-label">{t('otp')}</label>
                                                <Field name="otp" type="text" className={errors.otp && touched.otp ? "form-control error" : "form-control"} placeholder={t('otp')} />
                                            </div>
                                            <button type="submit" className="btn btn-orange d-block w-100 radius25"> {status == "loading" ? t('loading') : t('verify')}</button>
                                            <div className="font-w500 mt-4">
                                                {t("Don't receive verification code?")}{" "}
                                                <a href="#" onClick={() => { resendOTP() }} className="link-orange">
                                                    {t("Resend")}
                                                </a>
                                            </div>
                                            <div className='text-muted font14'>{t("Your verification code may be in your junk box!")}</div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}