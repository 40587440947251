import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Signin } from './signin'
import { Signup } from './signup'
import { VerifyOtp } from './verify-otp'
import { ForgotPassword } from './forgotPassword'
import { SponsorShip } from './SponsorShip'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../stores/hooks'
import { appState, getHomeAsync, setShowModal } from '../stores/appSlice'

export const Header = () => {
  const { showModal, languages } = useAppSelector(appState);
  const { i18n, t } = useTranslation()
  const dispatchApp = useAppDispatch()

  const setVisibleModel = (modelName: string) => {
    dispatchApp(setShowModal(modelName))
  }

  const onChangeLang = (lCode : string, langId : string) => {
    localStorage.setItem('lang', lCode);
    // dispatchApp(getHomeAsync(langId));
    i18n.changeLanguage(lCode)
  }

  return (
    <>
      <header className="home-header-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <nav className="row navbar">
                <div className="col-lg-4">
                  <Link to="/" className="navbar-brand">
                    <img height={"105px"} src="/images/logo-gray.png" alt="logo" />
                  </Link>
                </div>
                <div className="col-lg-8">
                  <ul className="ss-login-signup-right">
                    <li className="ss-shop">
                      <Link to="/sponsor" >
                        <span>
                          <img
                            src="/images/icons/sponsorships.svg"
                            alt="Sponsorships"
                          />
                        </span>
                        {t('Sponsorships')}
                      </Link>
                    </li>
                    <li className="ss-shop">
                      <Link to="/store">
                        <span>
                          <img src="/images/icons/shop.svg" alt="shop" />
                        </span>
                        {t('shop')}
                      </Link>
                    </li>

                    <li className="ss-shop">
                      <div className="dropdown lang-dropdown">
                        <a className="dropdown-toggle1" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                          <span>
                            <img
                              src="/images/icons/language.svg"
                              alt="language"
                            />
                          </span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-center mt-2" aria-labelledby="dropdownMenuLink">
                          {languages?.data?.map((lang: any, index: number) => {
                            return (
                              <li key={index} onClick={() => { onChangeLang(lang.language_code, lang.language_id) }}>
                                <a className={languages?.data?.length - 1 === index ? "dropdown-item" : "dropdown-item border-bottom"} href="#">
                                  <img src={`/images/${lang.language_code}.png`} alt="language" className="pe-2" />
                                  {lang.language_name}
                                </a>
                              </li>)
                          })}
                        </ul>
                      </div>
                    </li>

                    <li className="ss-login-signup">
                      <a
                        href="#"
                        className="link-black"
                        onClick={() => setVisibleModel('signin')}
                      >
                        {t('log_in')}
                      </a>
                      |
                      <a
                        href="#"
                        className="link-orange"
                        onClick={() => setVisibleModel('signup')}
                      >
                        {t('sign_up')}
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      {showModal == 'signup' ? (
        <Signup setVisibleModel={setVisibleModel} />
      ) : showModal == 'signin' ? (
        <Signin setVisibleModel={setVisibleModel} />
      ) : showModal == 'verify-otp' ? (
        <VerifyOtp setVisibleModel={setVisibleModel} />
      ) : showModal == 'forgot-password' ? (
        <ForgotPassword setVisibleModel={setVisibleModel} />
      ) : showModal == 'sponsor' ? (
        <SponsorShip setVisibleModel={setVisibleModel} />
      ) : null}

    </>
  )
}
