import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { Tooltip } from "react-tooltip"
import { getMyOrdersAsync } from "../stores/myOrderSlice"
import { getProductsAsync } from "./../stores/cartSlice"

const MyOrders = () => {
  const dispatchApp = useAppDispatch()
  const { orders, status } = useAppSelector((state: any) => state.order)
  const { allProducts } = useAppSelector((state: any) => state.cart)
  const [limit, setLimit] = useState(3)
  const [page, setPage] = useState(1)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    dispatchApp(getMyOrdersAsync())
    dispatchApp(getProductsAsync())
  }, [])

  // return (
  //   <>
  //     <div className="m-5">
  //       <div className="position-relative contests-reg font-w300 font45 pb-2 mb-3 text-center mt-3">
  //         {t("my_orders")}
  //       </div>
  //       <div className=" font-w300 font22 pt-4 mt-4 text-center">
  //         {t("Comming Soon")}
  //       </div>
  //     </div>
  //   </>
  // )

  return (
    <>
      <div className="position-relative contests-reg font-w300 font45 pb-2 mb-3 text-center mt-3">
        {t("my_orders")}
      </div>

      {orders?.length == 0 ? (
        <div className=" font-w300 font22 pt-4 mt-4 text-center">
          {t("No Orders Yet")}
        </div>
      ) : (
        orders.map((order: any) => {
          return (
            <>
              {order?.req_data?.line_items.map((item: any) => {
                return (
                  <div key={order?.req_data?.product_order_id}>
                    {allProducts.map((product: any) => {
                      return product?.id === item?.product_id ? (
                        <div
                          key={`${order?.product_order_id}_${product?.id}`}
                          className="resp-tabs-container hor_1"
                        >
                          <section className="contentpart">
                            <div className="container">
                              <div
                                className="aritile-gridview"
                                id="aritile-gridview"
                              >
                                <div className="ariclebox">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="d-flex justify-content-between">
                                        <p>
                                          {t("Order Id")} :{" "}
                                          {order?.product_order_id}
                                        </p>
                                        <p>
                                          {t("Ordered At")} :{" "}
                                          {new Date(
                                            order?.created_at
                                          ).toDateString()}
                                          <a
                                            href="#!"
                                            data-tooltip-id={`address_${order?.product_order_id}`}
                                          >
                                            <img
                                              src="/images/icons/info.svg"
                                              alt="info"
                                              className="ms-2"
                                            />
                                          </a>
                                          <Tooltip
                                            id={`address_${order?.product_order_id}`}
                                            closeOnScroll
                                            openOnClick
                                            opacity={100}
                                          >
                                            <div
                                              style={{
                                                height: 200,
                                                width: 250,
                                              }}
                                              className="row"
                                            >
                                              <p>{t("Address Data")} </p>
                                              <p>
                                                {t("first_name")} :{" "}
                                                {
                                                  order?.req_data?.address_to
                                                    ?.first_name
                                                }
                                              </p>
                                              <p>
                                                {t("last_name")} :{" "}
                                                {
                                                  order?.req_data?.address_to
                                                    ?.last_name
                                                }
                                              </p>
                                              <p>
                                                {t("phone")} :{" "}
                                                {
                                                  order?.req_data?.address_to
                                                    ?.phone
                                                }
                                              </p>
                                              <p>
                                                {"Country"} :{" "}
                                                {
                                                  order?.req_data?.address_to
                                                    ?.country
                                                }
                                              </p>
                                              <p>
                                                {t("Region")} :{" "}
                                                {
                                                  order?.req_data?.address_to
                                                    ?.region
                                                }
                                              </p>
                                              <p>
                                                {t("Main Address")} :{" "}
                                                {
                                                  order?.req_data?.address_to
                                                    ?.address1
                                                }
                                              </p>
                                              <p>
                                                {t("City")} :{" "}
                                                {
                                                  order?.req_data?.address_to
                                                    ?.city
                                                }
                                              </p>
                                              <p>
                                                {t("Zip")} :{" "}
                                                {
                                                  order?.req_data?.address_to
                                                    ?.zip
                                                }
                                              </p>
                                              <p>
                                                {t("email")} :{" "}
                                                {
                                                  order?.req_data?.address_to
                                                    ?.email
                                                }
                                              </p>
                                            </div>
                                          </Tooltip>
                                        </p>
                                      </div>
                                      <div className="contests-sec text-center">
                                        <div className="resp-tabs-container hor_1">
                                          <div className="artiletab mt-5">
                                            <div className="row align-content-center">
                                              <div className="col-lg-1">
                                                <div className="articl-icon">
                                                  <img
                                                    width={"300px"}
                                                    src={
                                                      product.images[0]?.src ||
                                                      "/images/logo.png"
                                                    }
                                                    className="d-block m-auto"
                                                    alt="tshirt"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-3">
                                                <div className="article-title">
                                                  {item?.name}
                                                </div>
                                              </div>
                                              <div className="col-lg-6">
                                                <div className="info row">
                                                  <p
                                                    className="font15 col-6"
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        product.description.substring(
                                                          0,
                                                          100
                                                        ) + "...",
                                                    }}
                                                  ></p>
                                                  <h6 className="col-6">
                                                    {item?.size && (
                                                      <h6 className="mb-2">
                                                        Size : {item?.size}
                                                      </h6>
                                                    )}
                                                    {item?.color && (
                                                      <h6 className="mb-2">
                                                        Color : {item?.color}
                                                      </h6>
                                                    )}
                                                    Quantity : {item?.quantity}
                                                  </h6>
                                                </div>
                                              </div>
                                              <div className="col-lg-2">
                                                <span className="photos-buy">
                                                  ${" "}
                                                  {order?.shopping_amount / 100}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {orders?.length == page * limit ||
                                        status == "loading" ? (
                                          <div className="mt-3">
                                            <a
                                              onClick={() => {
                                                setPage(page + 1)
                                              }}
                                              className="btn btn-orange box-shadow view-more-btn"
                                            >
                                              {status == "loading"
                                                ? t("loading")
                                                : t("view-more")}{" "}
                                            </a>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      ) : (
                        <></>
                      )
                    })}
                  </div>
                )
              })}
            </>
          )
        })
      )}
    </>
  )
}
export default MyOrders
