import { t } from "i18next"
import { useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { addToCart, getAchievementProductAsync } from "./../stores/cartSlice"
import { useSelector } from "react-redux"

const AchievementStore = () => {
  const params = useParams()
  const location = useLocation()
  const { allProducts, state } = useSelector((state: any) => state.cart)
  const dispatchApp = useAppDispatch()
  const [activeImg, setActiveImg] = useState(0)
  const [selectedColor, setSelectedColor] = useState("")
  const [selectedSize, setSelectedSize] = useState("")
  const [selectedVariantId, setSelectedVariantId] = useState("")
  const [selectedVariantPrice, setSelectedVariantPrice] = useState(0)
  const [selectedVarient, setSelectedVarient] = useState<any>([])
  const [productQuantity, setProductQuantity] = useState(1)
  const [selectedProduct, setSelectedProduct] = useState<any>({})
  const [subTotal, setSubTotal] = useState(0)
  const [finalVariant, setFinalVariant] = useState<any>({})
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowMessage(true)
    }, 4000)
  })

  useEffect(() => {
    setSubTotal(productQuantity * selectedVariantPrice)
  }, [productQuantity, selectedVariantPrice])

  useEffect(() => {
    getProductDetails()
  }, [allProducts])

  useEffect(() => {
    defaultVariant()
  }, [selectedProduct])

  useEffect(() => {
    dispatchApp(getAchievementProductAsync())
  }, [params.product_id])

  const getProductDetails = () => {
    for (let i = 0; i < allProducts.length; i++) {
      if (allProducts[i]?.id == params.product_id) {
        setSelectedProduct(allProducts[i])
        break
      }
    }
  }

  const defaultVariant = () => {
    let isSet = false
    if (selectedProduct.id) {
      selectedProduct.variants.forEach((variant: any) => {
        if (variant.is_default) {
          setSelectedVarient(variant.options)
          setSelectedVariantPrice(variant.price / 100)
          setSelectedVariantId(variant?.id)
          setFinalVariant(variant)
          isSet = true
        }
      })
    }
    if (!isSet) {
      setSelectedVarient([])
    }
  }

  const getVariantId = () => {
    let _variant_id = null
    let _price = 0
    for (let i = 0; i < selectedProduct?.variants.length; i++) {
      if (
        selectedProduct?.variants[i]?.options[0] == selectedVarient[0] &&
        selectedProduct?.variants[i]?.options[1] == selectedVarient[1]
      ) {
        _variant_id = selectedProduct?.variants[i]?.id
        _price = selectedProduct?.variants[i]?.price / 100
        setFinalVariant(selectedProduct?.variants[i])
        break
      }
    }
    setSelectedVariantId(_variant_id)
    setSelectedVariantPrice(_price)
  }

  const renderColorOptions = () => {
    return (
      <>
        {selectedProduct?.options.map((option: any, opindex: number) => {
          return (
            !(option.name == "Colors") && (
              <div
                key={`options${opindex}`}
                className="font18 font-w500 options"
              >
                <div>{option.name}:</div>
                {option.values.map((color: any, index: number) => {
                  return (
                    <button
                      key={`color${index}`}
                      className={
                        Number(selectedVarient[opindex]) === Number(color?.id)
                          ? "op-btn active-box"
                          : "op-btn"
                      }
                      onClick={() => {
                        let _selectedVarient = [...selectedVarient]
                        _selectedVarient[opindex] = color.id
                        setSelectedVarient(_selectedVarient)
                        if (option.type == "color") {
                          setSelectedColor(color?.title)
                        } else {
                          setSelectedSize(color?.title)
                        }
                        getVariantId()
                      }}
                    >
                      {option.type === "color" ? (
                        <span
                          className="color-circle"
                          style={{
                            backgroundColor: `${color?.colors[0]}`,
                          }}
                        ></span>
                      ) : (
                        color.title
                      )}
                    </button>
                  )
                })}
              </div>
            )
          )
        })}
      </>
    )
  }
  return selectedProduct.options != undefined ? (
    <>
      <div className="contentpart">
        <div className="container">
          <div className="position-relative contests-reg pb-3 mb-3 text-center">
            <p className="font-w300 font50 mb-2">{selectedProduct?.title}</p>
            <p>
              {t(
                "This is an exclusive item that is only available to users that completed this specific achievement."
              )}
            </p>
          </div>

          <div className="row">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="slider-flexslider">
                  <div className="storeproduct">
                    <div className="store-pro-bg">
                      <div id="carousel" className="flexslider thumbe">
                        <div className="carousel slide" data-bs-ride="true">
                          <div className="carousel-indicators">
                            {selectedProduct.images?.map(
                              (image: any, i: number) => {
                                return (
                                  <button
                                    id={`dot_${i}`}
                                    type="button"
                                    className={i == activeImg && "active"}
                                    onClick={() => {
                                      setActiveImg(i)
                                    }}
                                    data-bs-target={`#storepro`}
                                    data-bs-slide-to={`${i}`}
                                    aria-current="true"
                                    aria-label={`Slide ${i}`}
                                  ></button>
                                )
                              }
                            )}
                          </div>
                          <div className="carousel-inner">
                            {selectedProduct.images?.map(
                              (image: any, index: number) => {
                                return (
                                  <div
                                    key={`proImg_${index}`}
                                    className={
                                      index == activeImg
                                        ? "carousel-item active"
                                        : "carousel-item"
                                    }
                                  >
                                    <img
                                      width={"300px"}
                                      src={selectedProduct.images[index].src}
                                      className="d-block m-auto"
                                      alt="tshirt"
                                    />
                                  </div>
                                )
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* <div className="font32 text-black">{product.title}</div> */}
                <div className="price font32 text-black">
                  ${selectedVariantPrice}
                </div>
                <p
                  className="font15"
                  dangerouslySetInnerHTML={{
                    __html: selectedProduct.description,
                  }}
                ></p>
                {renderColorOptions()}
                <div className="my-4">
                  <div className="row align-items-center">
                    <div className="col-4 cartlisting">
                      <div className="input-group">
                        <button
                          className="input-group-text"
                          onClick={() => {
                            setProductQuantity(productQuantity - 1)
                          }}
                          disabled={productQuantity == 1}
                        >
                          <img src="/images/icons/minus-icon.svg" />
                        </button>
                        <input
                          type="number"
                          className="form-control"
                          value={productQuantity}
                          min="1"
                          aria-label="Amount (to the nearest dollar)"
                        />
                        <button
                          className="input-group-text"
                          onClick={() => {
                            setProductQuantity(productQuantity + 1)
                          }}
                        >
                          <img src="/images/icons/plus-icon.svg" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Link
                    to="/checkout"
                    state={{
                      products: [
                        { ...selectedProduct, quantities: productQuantity },
                      ],
                      totalCost: subTotal,
                      variants: [
                        {
                          finalVariant,
                          color: selectedColor,
                          size: selectedSize,
                        },
                      ],
                      isAchievementCheckout: true,
                    }}
                    className="btn btn-border-orange view-more-btn"
                  >
                    {t("Proceed To Checkout")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    state !== "loading" && showMessage && (
      <div className=" font-w300 font22 pt-4 m-4 text-center">
        {t("No Item Found")}
      </div>
    )
  )
}
export default AchievementStore
