import AxiosInstance from "../utils/AxiosInstance"
import Config from "../utils/config"

export const signup = async (data: any) => {
  return await AxiosInstance.post("/auth/signup", data)
}

export const verifyOtp = async (data: any) => {
  return await AxiosInstance.post("/auth/verify-otp", data)
}

export const signin = async (data: any) => {
  return await AxiosInstance.post("/auth/signin", data)
}

export const getProfile = async () => {
  return await AxiosInstance.get("/users/get-my-profile")
}

export const getLanguages = async () => {
  return await AxiosInstance.get("/language")
}

export const getTransactions = async () => {
  return await AxiosInstance.get("/users/get-transactions")
}

export const updateProfile = async (data: any) => {
  return await AxiosInstance.put("/users/update-my-profile", data)
}

export const getContest = async (
  status: string,
  limit: number,
  page: number
) => {
  return await AxiosInstance.get(
    `/contest/get-by-status?status=${status}&limit=${limit}&page=${page}`
  )
}

export const getContestById = async (id: string) => {
  return await AxiosInstance.get(`/contest/get-by-id/${id}`)
}

export const getContestRoundByContestId = async (id: string) => {
  // return await AxiosInstance.get(`/contest-round/get-by-contest-id/${id}`);
  return await AxiosInstance.get(`/contest-round/get-all-with-games/${id}`)
  return await AxiosInstance.get(`/contest-round/get-detail-with-credit/${id}`)
}

export const getCmsList = async (
  limit: number,
  page: number,
  language_id: string,
  filterValue: any,
  sport_id: any
) => {
  return await AxiosInstance.get(
    `/cms/list?page=${page}&limit=${limit}&language_id=${language_id}&filterValue=${filterValue}&sport_id=${sport_id}`
  )
}

export const getCmsListByLangCode = async (limit: number, page: number) => {
  return await AxiosInstance.get(`/cms/list?page=${page}&limit=${limit}`)
}

export const getCmsById = async (id: any) => {
  return await AxiosInstance.get(`/cms/view/${id}`)
}

export const buyMediaCredit = async (data: any) => {
  return await AxiosInstance.post(`/payment/make`, data)
}

export const getBankInfo = async () => {
  return await AxiosInstance.get(`/user-info/get-bank`)
}

export const addBankDetails = async (data: any) => {
  return await AxiosInstance.post("/user-info/create-bank", data)
}

export const updateBankDetails = async (data: any) => {
  return await AxiosInstance.put("/user-info/update-bank", data)
}

export const deleteBankDetails = async (id: any) => {
  return await AxiosInstance.delete(`/user-info/delete-by-id/${id}`)
}

export const applySponsorship = async (data: any) => {
  return await AxiosInstance.post("/sponsorship/apply", data)
}

export const forgotPassword = async (data: any) => {
  return await AxiosInstance.post("/auth/forgot-password", data)
}

export const resetPassword = async (data: any) => {
  return await AxiosInstance.post("/auth/reset-password", data)
}

export const participant = async (data: any) => {
  return await AxiosInstance.post("/contest-round-participant/create", data)
}

export const addParticipant = async (data: any) => {
  return await AxiosInstance.post("/contest-round-participant/create", data)
}

export const cmsAccessBuy = async (data: any) => {
  return await AxiosInstance.post("/cms-access/buy", data)
}

export const resendOtp = async (data: any) => {
  return await AxiosInstance.post("/auth/resend-otp", data)
}

export const getContestRoundGamesById = async (id: string) => {
  // return await AxiosInstance.get(`/contest-round/get-by-contest-id/${id}`);
  return await AxiosInstance.get(
    `/contest-round/get-all-with-games-detail/${id}`
  )
  return await AxiosInstance.get(`/contest-round/get-detail-with-credit/${id}`)
}

export const getHome = async (id: string) => {
  return await AxiosInstance.get(`/home/get-by-id/${id}`)
}

export const getActivity = async () => {
  return await AxiosInstance.get(`/activity/get-activity`)
}

export const getPopularCMS = async (lang_id: any, user_id: any) => {
  return await AxiosInstance.get(
    `/cms/cms-most-purchased?language_id=${lang_id}&user_id=${user_id}`
  )
}

export const getPageBySlug = async (slug: string) => {
  return await AxiosInstance.get(`/page/get-by-slug/${slug}`)
}

export const createPaymentSession = async (data: any) => {
  return await AxiosInstance.post(`/payment/create-session`, data)
}

export const paymentSuccess = async (id: string) => {
  return await AxiosInstance.get(`/payment/success/${id}`)
}

export const paymentCancle = async (id: string) => {
  return AxiosInstance.get(`/payment/cancel/${id}`)
}

export const getProducts = async () => {
  return await AxiosInstance.get(
    `/printify/shops/${Config.DEFAULT_STORE}/products.json`
  )
}

export const createProductSession = async (data: any) => {
  return await AxiosInstance.post(`/payment/create-product-session`, data)
}

export const productSuccess = async (id: string) => {
  return await AxiosInstance.get(`/payment/product-success/${id}`)
}

export const myOrders = async () => {
  return await AxiosInstance.get(`/order/list`)
}

export const getShippingCost = async (data: any) => {
  return await AxiosInstance.post(
    `/printify/shops/${Config.DEFAULT_STORE}/orders/shipping.json`,
    data
  )
}

export const getAchievements = async (params: any) => {
  return await AxiosInstance.get(
    `/achievement/get-by-user-id?page=${params.page}&limit=${params.limit}`
  )
}

export const getAchievementProduct = async () => {
  return await AxiosInstance.get(
    `/printify/shops/${Config.ACHIEVEMENT_STORE}/products.json`
  )
}

export const getPodcastList = async (
  limit: number,
  page: number,
  language_id: number,
  filterValue: any,
  sport_id: any
) => {
  return await AxiosInstance.get(
    `/podcast/get-by-language-id/${language_id}?page=${page}&limit=${limit}&filterValue=${filterValue}&sport_id=${sport_id}`
  )
}

export const PodcastAccessBuy = async (data: any) => {
  return await AxiosInstance.post("/podcast-access/buy", data)
}

export const getPodcastById = async (id: any) => {
  return await AxiosInstance.get(`/podcast/get-by-id/${id}`)
}

export const getVideoList = async (
  limit: number,
  page: number,
  language_id: number,
  filterValue: any,
  sport_id: any
) => {
  return await AxiosInstance.get(
    `/video/get-by-language-id/${language_id}?page=${page}&limit=${limit}&filterValue=${filterValue}&sport_id=${sport_id}`
  )
}

export const VideoAccessBuy = async (data: any) => {
  return await AxiosInstance.post("/video-access/buy", data)
}

export const getVideoById = async (id: any) => {
  return await AxiosInstance.get(`/video/get-by-id/${id}`)
}
