const Config = {
  // BASE_URL: "https://safetysurvivorapi.alliancetek.net/v1",
  // sasToken: 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-12-31T18:27:26Z&st=2023-06-06T10:27:26Z&spr=https,http&sig=v7doE41YduBXfmyQmVf0z5o6sHtEMU3tNZ7ykkji7Ho%3D',
  // storageAccountName: 'safetysurvivorqa',
  // CDN_URL: "https://safetysurvivorqa.blob.core.windows.net/images/",
  // STRIPE_KEY: "pk_test_CoqhiBoRo5EJ1G93V2ZQWVtV",
  // STORE_ENDPOINT: "https://api.printify.com/v1/",
  // DEFAULT_STORE: 10559160,
  // STORE_TOKEN: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjoiVTJGc2RHVmtYMThGNHNKWHdUL0xZaEJzYjBOUFBFL05jMlVWd1hiUkN4M1hIN3EvT0xoNE9WN1plZFdvZjQ5V1hsNUxrTXk4ODgveWZoMkw5ejI4Zm02aU9rYjRJVWJaZU80RnVFYWdrSzQ9IiwiaWF0IjoxNjkxOTg5ODU4LCJleHAiOjE2OTIwNzYyNTh9.PaDJyBsFq4oDWk4R7GW62jZSIM4XphQjxtk3CAf3bEA",
  // ACHIEVEMENT_STORE: 11102667

  BASE_URL: "https://webapi.safetysurvivor.com/v1",
  sasToken:
    "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-08-08T19:08:21Z&st=2023-08-08T11:08:21Z&spr=https,http&sig=97EVAKI0lsWZihXo%2FZAq01csRV0qtEuyyIcyV90KTTQ%3D",
  storageAccountName: "safetysurrvivormedia",
  STORE_ENDPOINT: "https://api.printify.com/v1/",
  CDN_URL: "https://ssmediacdn.azureedge.net/images/",
  DEFAULT_STORE: 10559160,
  STRIPE_KEY:
    "pk_live_51NKhEbDQn9pFPD4kVKuYAuSx1PtggH5TZX3qmcwAcgdiFMT1z278156ePozAK613YoRCMnXO8fgfx07bPApt7E7w00HceQSwdW",
  STORE_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjoiVTJGc2RHVmtYMThGNHNKWHdUL0xZaEJzYjBOUFBFL05jMlVWd1hiUkN4M1hIN3EvT0xoNE9WN1plZFdvZjQ5V1hsNUxrTXk4ODgveWZoMkw5ejI4Zm02aU9rYjRJVWJaZU80RnVFYWdrSzQ9IiwiaWF0IjoxNjkxOTg5ODU4LCJleHAiOjE2OTIwNzYyNTh9.PaDJyBsFq4oDWk4R7GW62jZSIM4XphQjxtk3CAf3bEA",
  ACHIEVEMENT_STORE: 11102667,
}
export default Config
