import { useEffect, useState } from "react"
import {
  videoState,
  setShowPerchaseModal,
  videoAccessBuyAsync,
} from "../stores/videoSlice"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import { appState, getProfileAsync } from "../stores/appSlice"
import { Link, useNavigate } from "react-router-dom"

const PurchaseModalForVideo = (props: any) => {
  const dispatchApp = useAppDispatch()
  const { t } = useTranslation()
  const { profile } = useAppSelector(appState)
  const navigate = useNavigate()
  const { videoAccessBuy, showPerchaseModal } = useAppSelector(videoState)
  const [isBuy, setIsBuy] = useState(false)

  const buyVideo = () => {
    const data = {
      video_id: showPerchaseModal.video_id,
    }
    setIsBuy(true)
    dispatchApp(videoAccessBuyAsync(data))
  }

  useEffect(() => {
    if (videoAccessBuy.statusCode == 201 && isBuy) {
      navigate("/video-detail/" + showPerchaseModal.video_id)
      dispatchApp(getProfileAsync())
      setIsBuy(false)
      dispatchApp(setShowPerchaseModal({ video_id: "", title: "", credit: "" }))
    }
  }, [videoAccessBuy, isBuy])

  return (
    <>
      {props.isShow && (
        <Modal
          show={showPerchaseModal.video_id != ""}
          onHide={() =>
            dispatchApp(
              setShowPerchaseModal({ video_id: "", title: "", credit: "" })
            )
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {Number(profile?.media_credit) >= Number(showPerchaseModal.credit)
                ? t("purchase-confirm")
                : t("INSUFFICIENT CREDITS")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {Number(profile?.media_credit) >=
            Number(showPerchaseModal.credit) ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Are you sure you would like to buy <b>title</b> article by using <b>credit</b> media credits?",
                    {
                      title: showPerchaseModal.title,
                      credit: showPerchaseModal.credit,
                    }
                  ),
                }}
              ></div>
            ) : (
              <div>
                {t("You do not have enough media credits to purchase")}{" "}
                <b>{showPerchaseModal.title}</b>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-orange box-shadow"
              onClick={() =>
                dispatchApp(
                  setShowPerchaseModal({ video_id: "", title: "", credit: "" })
                )
              }
            >
              {t("Back")}
            </button>
            {Number(profile?.media_credit) >=
            Number(showPerchaseModal.credit) ? (
              <button
                onClick={() => buyVideo()}
                className="btn btn-orange box-shadow"
              >
                {" "}
                {t("yes")}{" "}
              </button>
            ) : (
              <Link
                to="/buy-media-credit"
                onClick={() => {
                  dispatchApp(
                    setShowPerchaseModal({
                      video_id: "",
                      title: "",
                      credit: "",
                    })
                  )
                }}
                className="btn btn-orange box-shadow"
              >
                {" "}
                {t("buy_media_credits")}{" "}
              </Link>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}
export default PurchaseModalForVideo
