import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useLocation, Link } from "react-router-dom"
import {
  contestState,
  getContestAsync,
  resetContestById,
} from "../stores/contestSlice"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import moment from "moment"
import { generateFileUrl } from "../utils/AzureStorageBlob"
import { numberFormat, localNumber } from "../utils/commonFun"
import Rating from "./rating"
import RemoteLanguage from "./remoteLang"
import { Tooltip } from "react-tooltip"

const CloseContest = () => {
  const dispatchApp = useAppDispatch()
  const { contest, status } = useAppSelector(contestState)
  const [limit, setLimit] = useState(3)
  const [page, setPage] = useState(1)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    let data = {
      limit: 3,
      page: page,
      status: "Close",
    }
    dispatchApp(getContestAsync(data))
  }, [limit, page])

  return (
    <section className="contentpart">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="contests-sec text-center">
              <div className="position-relative contests-reg font-w300 font45 pb-2 mb-3">
                {t("closed_contests")}
              </div>
              {contest?.length == 0 && (
                <div className=" font-w300 font22 pt-4 mt-4">
                  {t("No Contests Completed")}
                </div>
              )}

              <div className="row">
                {contest?.map((item: any, index: number) => {
                  return (
                    <div
                      key={`contest_ ${index}`}
                      className="col-lg-4 col-md-12"
                    >
                      <div
                        onClick={() => {
                          dispatchApp(resetContestById())
                          navigate(`/contest-detail/${item.contest_id}`)
                        }}
                        className="pointer sports-list"
                      >
                        <div className="home-sports-box-bg">
                          <div className="sportimg">
                            <img
                              src={generateFileUrl(item?.Sport?.logo)}
                              alt="Football"
                            />
                          </div>
                          <h2>
                            <RemoteLanguage field={item?.title} />
                            {item?.rules_file && (
                              <Link
                                onClick={(e) => {
                                  e.stopPropagation()
                                }}
                                to={generateFileUrl(item?.rules_file)}
                                target="_blank"
                              >
                                <img
                                  src="/images/icons/notepad.svg"
                                  alt="notepad"
                                  className="ms-1"
                                />
                              </Link>
                            )}
                          </h2>
                          <span className="prize-per-winning-entry">
                            {t("prize_per_winning_entry")}{" "}
                            <span>{numberFormat(item?.price_pool)}</span>
                          </span>
                          <div className="font-w500 text-black">
                            <RemoteLanguage field={item?.Sport?.sport_name} />
                          </div>
                          <div className="font12">{t("Completed")}</div>
                        </div>
                        <Rating complexity={item.complexity} />
                        <div className="hrline"></div>
                        <div className="font16 font-w500 line18 mb-3 fx-center">
                          {/* <img src="/images/icons/tiket.svg" alt="tiket" className="me-3" />
                                                    <span className="font20"> {localNumber(item?.user_contest_credit)}</span>
                                                    /
                                                    <span className="font12">{localNumber(item?.user_contest_total_credit)}</span>
                                                    {item?.user_contest_credit > 0 && <img src="/images/icons/green-check.svg" alt="tiket" className="ms-2" />} */}

                          {item?.user_contest_credit > 0 ? (
                            <>
                              <span className="win-capsule">
                                <img
                                  src="/images/award.png"
                                  alt="award"
                                  className="ms-2"
                                />
                                <span className="ps-2">
                                  {t("Price Won:")}{" "}
                                  {numberFormat(
                                    item?.price_pool * item?.user_contest_credit
                                  )}
                                </span>
                              </span>
                              <a
                                onClick={(e) => e.stopPropagation()}
                                href="#!"
                                data-tooltip-id={`my-tooltip-${item.contest_id}`}
                              >
                                <img
                                  src="/images/icons/info.svg"
                                  alt="info"
                                  className="ms-2"
                                />
                              </a>
                              <Tooltip
                                id={`my-tooltip-${item.contest_id}`}
                                content={t(
                                  `${
                                    item?.ContestWinners &&
                                    item?.ContestWinners[0]?.payment_status
                                  }`
                                )}
                                closeOnScroll
                                openOnClick
                              />
                            </>
                          ) : (
                            <span className="lost-capsule">
                              <img
                                src="/images/icons/tiket-red.svg"
                                alt="tiket"
                                className="ms-2"
                              />
                              <span className="ps-2">
                                {t("All Enteries Lost")}
                              </span>
                            </span>
                          )}
                        </div>
                        <div>
                          <RemoteLanguage field={item?.description} />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              {contest?.length == page * limit || status == "loading" ? (
                <div className="mt-3">
                  <a
                    onClick={() => {
                      setPage(page + 1)
                    }}
                    className="btn btn-orange box-shadow view-more-btn"
                  >
                    {status == "loading" ? t("loading") : t("view-more")}{" "}
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default CloseContest
