import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useAppDispatch, useAppSelector } from '../stores/hooks';
import { appState, forgotPasswordAsync, reSetForgotPassword, resendOTPAsync, resetPasswordAsync } from '../stores/appSlice';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ErrorAlert from './errorAlert';
import { showErrorToast, showSuccessToast } from '../utils/commonFun';
import store from '../stores';

export const ForgotPassword = (props: any) => {
    const dispatchApp = useAppDispatch();
    const [serverErrorMessage, setServerErrorMessage] = useState("");
    const { t } = useTranslation();
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showPass1, setShowPass1] = useState(false);
    const [email, setEmail] = useState("");
    const { status, forgotPassword, resetPassword, resendOtp } = useAppSelector(appState);

    const onSubmitOtpForm = async (values: any, actions: any) => {
        const data = {
            email: values.email,
        }
        setEmail(values.email);
        dispatchApp(forgotPasswordAsync({ data, setErrors: actions.setErrors }));
    }

    const onSubmitPassForm = async (values: any, actions: any) => {
        const data = {
            email_otp: values.email_otp,
            email: email,
            password: values.password,
            confirmPassword: values.confirmPassword,
        }
        dispatchApp(resetPasswordAsync(data));
    }
    let passRegx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/g;
    let emailRegx = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const validationSchema = Yup.object().shape({
        email: Yup.string().matches(emailRegx, t('invalid-email')).required(t('email-required')),
    });

    const setPassValidationSchema = Yup.object().shape({
        email_otp: Yup.string().matches(/^[0-9]{6}$/, t("invalid-otp")).required(t("otp-required")),
        password: Yup.string().matches(passRegx, t('invalid-password')).required(t('password-required')),
        confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Your confirm passwords do not match.').required(t('confirm-password-required'))
    });


    useEffect(() => {
        if (forgotPassword.statusCode == 200) {
            setIsOtpSent(true);
        }
        if (forgotPassword.statusCode == 404) {
            showErrorToast(forgotPassword.message);
        }
    }, [forgotPassword])

    useEffect(() => {
        if (resetPassword.statusCode == 200) {
            setIsOtpSent(false);
            showSuccessToast(resetPassword.message)
            props.setVisibleModel("signin");
        } else if (resetPassword.statusCode == 400) {
            showErrorToast(resetPassword.message);
        }
    }, [resetPassword]);

    const closeModal = () => {
        props.setVisibleModel("");
        setIsOtpSent(false)
        dispatchApp(reSetForgotPassword())
    }

    const resendOTP = () => {
        const data = {
            email: email
        }
        dispatchApp(resendOTPAsync(data))
    }

    useEffect(() => {
        if (resendOtp.statusCode == 200) {
            showSuccessToast(resendOtp.message);
        }
    }, [resendOtp])

    return (
        <>
            <div className="ss-login-bg"></div>
            <div id="Login" className="ss-login">
                <div>
                    <a onClick={() => { closeModal() }}><img src="/images/icons/login-close.svg" width="35" alt="close" /></a>
                </div>
                <div className="mt-5">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="font26 font-w500 text-orange mb-2">
                                    {isOtpSent ? t('Reset Password') : t('forgot_password_heading')}
                                </div>
                                <div>{
                                    isOtpSent ? t("Please enter six digit verification code received on your registered email to set the new password")
                                        : t("Please enter your email address for reset your password")
                                }</div>
                                <div className="ss-hrline"></div>
                                {!isOtpSent ?
                                    <Formik
                                        initialValues={{ email: "" }}
                                        onSubmit={onSubmitOtpForm}
                                        validationSchema={validationSchema}
                                    >
                                        {({ errors, touched }) => (
                                            <Form>
                                                {serverErrorMessage ? <div className="alert alert-danger">{serverErrorMessage}</div> : null}
                                                <ErrorAlert errors={errors} touched={touched} />
                                                <div className="mb-3 relative">
                                                    <label className="form-label">{t('email')}</label>
                                                    <Field name="email" type="text" className={errors.email && touched.email ? "form-control error" : "form-control"} placeholder={t('email')} />
                                                </div>
                                                <button type="submit" className="btn btn-orange d-block w-100 radius25"> {status == "loading" ? t('loading') : t('submit')}</button>
                                            </Form>
                                        )}
                                    </Formik>
                                    :
                                    <Formik
                                        initialValues={{
                                            email_otp: "",
                                            password: "",
                                            confirmPassword: "",
                                        }}
                                        validationSchema={setPassValidationSchema}
                                        onSubmit={onSubmitPassForm}
                                    >
                                        {({ errors, touched }) => {
                                            return (
                                                <Form>
                                                    <ErrorAlert errors={errors} touched={touched} />
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="mb-3">
                                                                <label className="form-label">{t('otp')}</label>
                                                                <Field name="email_otp" onWheel={(event: any) => event.currentTarget.blur()} type="number" className={errors.email_otp && touched.email_otp ? "form-control error" : "form-control"} placeholder={t('otp')} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="mb-3 relative">
                                                                <label className="form-label">{t('new_password')}</label>
                                                                <Field name="password" type={showPass ? "text" : "password"} className={errors.password && touched.password ? "form-control error" : "form-control"} placeholder={t('password')} />
                                                                {showPass ?
                                                                    <img onClick={() => { setShowPass(!showPass) }} src="/images/icons/eye.svg" alt="eye" className="ss-form-eye-close" />
                                                                    :
                                                                    <img onClick={() => { setShowPass(!showPass) }} src="/images/icons/eye-close.svg" alt="email" className="ss-form-eye-close" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="mb-3 relative">
                                                                <label className="form-label">{t('confirm_new_password')}</label>
                                                                <Field name="confirmPassword" type={showPass1 ? "text" : "password"} className={errors.confirmPassword && touched.confirmPassword ? "form-control error" : "form-control"} placeholder={t('confirm_password')} />
                                                                {showPass1 ?
                                                                    <img onClick={() => { setShowPass1(!showPass1) }} src="/images/icons/eye.svg" alt="eye" className="ss-form-eye-close" />
                                                                    :
                                                                    <img onClick={() => { setShowPass1(!showPass1) }} src="/images/icons/eye-close.svg" alt="email" className="ss-form-eye-close" />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-orange d-block w-100 radius25 mt-3"> {status == "loading" ? t('loading') : t('submit')} </button>
                                                    <div className="font-w500 mt-4">
                                                        {t("Don't receive verification code?")}{" "}
                                                        <a href="#" onClick={() => { resendOTP() }} className="link-orange"> {t("Resend")}
                                                        </a>
                                                    </div>
                                                    <div className='text-muted font14'>{t("Your verification code may be in your junk box!")}</div>

                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}