import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { forgotPassword, getHome, getLanguages, getProfile, resendOtp, resetPassword, updateProfile } from "../api/appApi";

export interface AppStateInterface {
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  profile: any;
  homeData: any;
  resendOtp: any;
  updateProfile: any;
  languages: any;
  forgotPassword: any;
  resetPassword: any;
  email: string;
  isUserLoggedIn: boolean;
  appLoader: boolean;
  showModal : string,
}

const initialState: AppStateInterface = {
  status: "idle",
  profile: {},
  homeData: {},
  updateProfile: {},
  languages: [],
  forgotPassword: {},
  resetPassword: {},
  resendOtp: {},
  email: "",
  isUserLoggedIn: false,
  appLoader: false,
  showModal : ""
};

export const getProfileAsync = createAsyncThunk("Profile", async () => {
  const response = await getProfile();
  return response.data;
});

export const updateProfileAsync = createAsyncThunk("UpdateProfile", async (data: any) => {
  const response = await updateProfile(data);
  return response.data;
});

export const forgotPasswordAsync = createAsyncThunk("forgotPassword", async (data: any) => {
  const response = await forgotPassword(data.data);
  if (response.data.error && response.data.message) {
    const error: any = {};
    for (var i = 0; i < response.data.error.length; i++) {
      error[response.data.error[i].path] = response.data.error[i].msg;
    }
    data.setErrors(error)
  }
  return response.data;
});

export const resetPasswordAsync = createAsyncThunk("resetPassword", async (data: any) => {
  const response = await resetPassword(data);
  return response.data;
});

export const getLanguageAsync = createAsyncThunk("getLanguages", async () => {
  const response = await getLanguages();
  return response.data;
});

export const getHomeAsync = createAsyncThunk("getHome", async (id : string) => {
  const response = await getHome(id);
  return response.data;
});

export const resendOTPAsync = createAsyncThunk("resendOTP", async (data : any) => {
  const response = await resendOtp(data);
  return response.data;
});


export const appSlice = createSlice({
  name: "app",
  initialState,

  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setShowModal: (state, action: PayloadAction<string>) => {
      state.showModal = action.payload
    },
    reSetPassword: (state) => {
      state.resetPassword = {}
    },
    resetUpdateProfile: (state) => {
      state.updateProfile = {}
    },
    reSetForgotPassword: (state) => {
      state.forgotPassword = {}
    },
    setAppLoader: (state, action: PayloadAction<boolean>) => {
      state.appLoader = action.payload
    }
  },

  extraReducers: (builder) => {

    // get Profile
    builder.addCase(getProfileAsync.pending, (state) => {
      // state.status = "loading";
    })
    builder.addCase(getProfileAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.profile = action.payload.data;
      state.isUserLoggedIn = true
    })
    builder.addCase(getProfileAsync.rejected, (state) => {
      state.status = "failed";
    })

    // Update Profile
    builder.addCase(updateProfileAsync.pending, (state) => {
      // state.status = "loading";
    })
    builder.addCase(updateProfileAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.updateProfile = action.payload;
      if(action.payload.statusCode == 200 ){
        state.profile = action.payload.data?.[0];
      }
    })
    builder.addCase(updateProfileAsync.rejected, (state) => {
      state.status = "failed";
    })

    // Forgot Password
    builder.addCase(forgotPasswordAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(forgotPasswordAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.forgotPassword = action.payload;
    })
    builder.addCase(forgotPasswordAsync.rejected, (state) => {
      state.status = "failed";
    })

    // Reset Password
    builder.addCase(resetPasswordAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(resetPasswordAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.forgotPassword = {};
      state.resetPassword = action.payload;
    })
    builder.addCase(resetPasswordAsync.rejected, (state) => {
      state.status = "failed";
    })

    // Get Languages
    builder.addCase(getLanguageAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(getLanguageAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.languages = action.payload;
    })
    builder.addCase(getLanguageAsync.rejected, (state) => {
      state.status = "failed";
    })
   
    // Get Languages
    builder.addCase(getHomeAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(getHomeAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.homeData = action.payload.data[0];
    })
    builder.addCase(getHomeAsync.rejected, (state) => {
      state.status = "failed";
    })
   
    // Resend OTP
    builder.addCase(resendOTPAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(resendOTPAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.resendOtp = action.payload;
    })
    builder.addCase(resendOTPAsync.rejected, (state) => {
      state.status = "failed";
    })

  },
});

export const { setEmail, reSetPassword, setAppLoader, setShowModal, reSetForgotPassword, resetUpdateProfile } = appSlice.actions;
export const appState = (state: any) => state.app;
export default appSlice.reducer;
