import { t } from "i18next"
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../stores/hooks";
import { cmsState, pageBySlugAsync } from "../stores/cmsSlice";

const CommonPage = () => {

    const params = useParams(); 
    const location = useLocation();
    const { pageBySlug } = useAppSelector(cmsState);
    const dispatchApp = useAppDispatch();

    useEffect(()=>{
        getPageBySlug(params.slug)
    },[params.slug])

 

    const getPageBySlug = (slug : string) =>{
        dispatchApp(pageBySlugAsync(slug))
    }

    return (
        <div className="contentpart">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="position-relative contests-reg font-w300 font50 pb-3 mb-3 text-center">{pageBySlug?.data?.title}</div>
                        <div className="product-listing">
                            <div className="mt-5 mb-5" dangerouslySetInnerHTML={{__html : pageBySlug?.data?.content}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CommonPage