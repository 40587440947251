const ErrorAlert = (props: any) => {

  const comKeys = Object.keys(props.errors).filter(function (key, val) {
    return props.touched?.hasOwnProperty(key);
  });

  if (comKeys.length == 0) {
    return null;
  }

  return (
    <div className={`alert alert-danger ps-5 ${props?.className}`  }>
      <ul>
        {comKeys.map((key: string, index: number) => {
          return (<li>{props.errors[key]}</li>)
        })}
      </ul>
    </div >
  );

}
export default ErrorAlert
