import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { appState, setShowModal } from "../stores/appSlice"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { SponsorShip } from "./SponsorShip"
import { useEffect, useState } from "react"
import { setContest } from "../stores/contestSlice"
import { localNumber, showErrorToast } from "../utils/commonFun"
import { generateFileUrl } from "../utils/AzureStorageBlob"
import AxiosInstance from "../utils/AxiosInstance"
import Config from "../utils/config"
import { useSelector } from "react-redux"
import {
  decrementQuantity,
  getCartProducts,
  getProductsAsync,
  incrementQuantity,
  removeproduct,
} from "../stores/cartSlice"

export const AuthHeader = () => {
  const { i18n, t } = useTranslation()
  const { profile, languages } = useAppSelector(appState)
  const location = useLocation()
  const navigation = useNavigate()
  const dispatchApp = useAppDispatch()
  const { showModal } = useAppSelector(appState)
  const [showCartModel, setShowCartModel] = useState(false)
  const [cartProducts, setCartProducts] = useState<any>([])
  const [products, setProducts] = useState<any>([])
  const [listData, setListData] = useState<any>([])
  const [subTotal, setSubTotal] = useState(0)
  const [selectedVariants, setSelectedVariants] = useState<any>([])
  const [productsQuantities, setProductsQuantities] = useState<any>([])
  const [isCheckoutButtonDisbled, setIsCheckoutButtonDisbled] = useState(false)
  const dispatch = useAppDispatch()
  const { totalCount, cart, allProducts } = useSelector(
    (state: any) => state.cart
  )

  useEffect(() => {
    setProducts(allProducts)
  }, [allProducts])

  useEffect(() => {
    setListData([])
    if (
      (location.pathname == "/store" || location.pathname == "/checkout") &&
      cartProducts
    ) {
      listDataFun()
    }
  }, [cart, cartProducts])

  const getProducts = async () => {
    dispatch(getProductsAsync())
    setProducts(allProducts)
  }

  const listDataFun = () => {
    getProducts()
    var _listData = []
    var _subTotal = 0
    var _variant = []
    var _totalQuantites = []

    for (let i = 0; i < products.length; i++) {
      for (let j = 0; j < cartProducts.length; j++) {
        if (products[i].id === cartProducts[j].product_id) {
          for (let k = 0; k < products[i]?.variants.length; k++) {
            if (products[i]?.variants[k]?.id == cartProducts[j]?.variant_id) {
              _variant.push(products[i]?.variants[k])
              _subTotal +=
                (products[i]?.variants[k]?.price / 100) *
                cartProducts[j]?.quantity
              if (
                products[i]?.variants[k].quantity <
                  cartProducts[j]?.quantity - 1 &&
                !isCheckoutButtonDisbled
              ) {
                setIsCheckoutButtonDisbled(true)
              }
            }
          }
          _listData.push(products[i])
        }
        _totalQuantites.push(cartProducts[j].quantity)
      }
    }
    setSubTotal(_subTotal)
    setListData(_listData)
    setSelectedVariants(_variant)
    setProductsQuantities(_totalQuantites)
  }

  useEffect(() => {
    dispatchApp(setContest())
  }, [location.pathname])

  useEffect(() => {
    setCartProducts(cart)
  }, [cart])

  const onChangeLang = (lCode: any) => {
    localStorage.setItem("lang", lCode)
    i18n.changeLanguage(lCode)
  }

  const setVisibleModel = (modelName: string) => {
    dispatchApp(setShowModal(modelName))
  }

  return (
    <>
      {showCartModel && (
        <div id="Cart">
          <div className="position-relative">
            <div className="ss-login-bg"></div>
            <div className="ss-login cartdiv">
              <div>
                <a
                  href="#"
                  onClick={() => {
                    setShowCartModel(false)
                  }}
                >
                  <img
                    src="/images/icons/login-close.svg"
                    width="35"
                    alt="close"
                  />
                </a>
              </div>
              <div className="mt-5">
                {" "}
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="font30 font-w300 text-black bottomline-orange">
                        {t("Your Cart")}
                      </div>

                      <div className="cartlisting">
                        {cartProducts.map((product: any, index: number) => {
                          return (
                            <>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <img
                                    src={product?.img || "/images/logo.png"}
                                    alt=" "
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h2>{product?.name}</h2>
                                  <div className="price">
                                    {product.price} CAD
                                  </div>
                                  <div className="row align-items-center">
                                    <div className="col-8">
                                      <div className="input-group">
                                        <button
                                          className="input-group-text"
                                          onClick={() => {
                                            dispatch(
                                              decrementQuantity({
                                                index: index,
                                              })
                                            )
                                            if (
                                              productsQuantities[index] - 1 >=
                                              selectedVariants[index]?.quantity
                                            ) {
                                              setIsCheckoutButtonDisbled(false)
                                            }
                                          }}
                                          disabled={
                                            productsQuantities[index] == 1
                                          }
                                        >
                                          <img src="/images/icons/minus-icon.svg" />
                                        </button>

                                        <input
                                          type="text"
                                          className="form-control"
                                          value={product?.quantity}
                                          aria-label="Amount (to the nearest dollar)"
                                          min={1}
                                        />

                                        <button
                                          className="input-group-text"
                                          onClick={() => {
                                            dispatch(
                                              incrementQuantity({
                                                index: index,
                                              })
                                            )
                                          }}
                                        >
                                          <img src="/images/icons/plus-icon.svg" />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <a
                                      href="#"
                                      className="btn btn-orange deleteicon"
                                      onClick={() => {
                                        dispatch(
                                          removeproduct({ index: index })
                                        )
                                      }}
                                    >
                                      <img
                                        src="/images/icons/delete.svg"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              {listData[index + 1] && (
                                <div className="hrline"></div>
                              )}
                            </>
                          )
                        })}
                      </div>

                      {/* <div className="cartlisting">
                        {listData.map((product: any, index: number) => {
                          return (
                            <>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <img src={product?.images[0]?.src} alt=" " />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h2>{product?.title}</h2>
                                  <div className="price">
                                    $
                                    {selectedVariants[index]?.price / 100 ||
                                      selectedVariants.price / 100}
                                  </div>
                                  <div className="row align-items-center">
                                    <div className="col-8">
                                      <div className="input-group">
                                        <button
                                          className="input-group-text"
                                          onClick={() => {
                                            dispatch(
                                              decrementQuantity({
                                                index: index,
                                              })
                                            )
                                            if (
                                              productsQuantities[index] - 1 >=
                                              selectedVariants[index]?.quantity
                                            ) {
                                              setIsCheckoutButtonDisbled(false)
                                            }
                                          }}
                                          disabled={
                                            productsQuantities[index] == 1
                                          }
                                        >
                                          <img src="/images/icons/minus-icon.svg" />
                                        </button>
                                        {cartProducts.map(
                                          (cartProduct: any, i: number) => {
                                            if (
                                              cartProduct.product_id ==
                                              product.id
                                            ) {
                                              return selectedVariants.map(
                                                (
                                                  selectedVariant: any,
                                                  j: number
                                                ) => {
                                                  if (
                                                    selectedVariant.id ===
                                                    cartProduct.variant_id
                                                  ) {
                                                    return (
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        value={
                                                          cartProduct?.quantity
                                                        }
                                                        aria-label="Amount (to the nearest dollar)"
                                                        min={1}
                                                      />
                                                    )
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        )}

                                        <button
                                          className="input-group-text"
                                          onClick={() => {
                                            dispatch(
                                              incrementQuantity({
                                                index: index,
                                              })
                                            )
                                          }}
                                        >
                                          <img src="/images/icons/plus-icon.svg" />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <a
                                      href="#"
                                      className="btn btn-orange deleteicon"
                                      onClick={() => {
                                        dispatch(
                                          removeproduct({ index: index })
                                        )
                                      }}
                                    >
                                      <img
                                        src="/images/icons/delete.svg"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              {listData[index + 1] && (
                                <div className="hrline"></div>
                              )}
                            </>
                          )
                        })}
                      </div> */}

                      <div className="text-center my-5"></div>
                      {cart.length != 0 ? (
                        <div className="cart-totals">
                          <div className="card">
                            <div className="card-header">
                              {t("Cart Totals")}
                            </div>
                            <ul className="list-group list-group-flush">
                              <li className="list-group-item">
                                <div className="row align-items-center mt-3">
                                  <div className="col-6">
                                    <div className="font16 font-w500 text-black">
                                      {t("Sub Total")}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="font20 font-w500 text-black text-end">
                                      {subTotal.toFixed(2)} CAD
                                    </div>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <Link
                                    to="/checkout"
                                    state={{
                                      products: listData,
                                      totalCost: subTotal,
                                      variants: selectedVariants,
                                      isAchievementCheckout: false,
                                    }}
                                    className="btn btn-border-orange view-more-btn"
                                    onClick={(e: any) => {
                                      setShowCartModel(false)
                                    }}
                                  >
                                    {t("Proceed To Checkout")}
                                  </Link>
                                  {/* )} */}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div className="text-center">
                          {t("No Product Found")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <header>
        <div className="header-top">
          <div className="container">
            <div className="row">
              <div className="col-sm-10">{t("auth-header-top-text")}</div>
              <div className="col-sm-2 d-flex justify-content-end">
                <div className="dropdown lang-dropdown pe-4">
                  <a
                    className="dropdown-toggle1"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <img src="/images/icons/language.svg" alt="language" />
                    </span>
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-center mt45"
                    aria-labelledby="dropdownMenuLink"
                  >
                    {languages?.data?.map((lang: any, index: number) => {
                      return (
                        <li
                          key={index}
                          onClick={() => {
                            onChangeLang(lang.language_code)
                          }}
                        >
                          <a
                            className={
                              languages?.data?.length - 1 === index
                                ? "dropdown-item"
                                : "dropdown-item border-bottom"
                            }
                            href="#"
                          >
                            <img
                              src={`/images/${lang.language_code}.png`}
                              alt="language"
                              className="pe-2"
                            />
                            {lang.language_name}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>

                <div className="my-account">
                  <Link to={"/my-account"} className="link-black">
                    <img
                      width="25"
                      height="25"
                      src={
                        profile?.profile_image
                          ? generateFileUrl(profile?.profile_image)
                          : "/images/user.png"
                      }
                      alt="profile photo"
                      className="bg-info rounded-circle"
                    />
                    {t("My Account")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link to="/" className="navbar-brand1">
                <img height={"105px"} src="/images/logo-gray.png" alt="logo" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav text-center me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link
                      to="/"
                      className={
                        location.pathname == "/"
                          ? "nav-link active"
                          : "nav-link "
                      }
                      aria-current="page"
                    >
                      {t("home")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/contest/active"
                      className={
                        location.pathname == "/contest/active"
                          ? "nav-link active"
                          : "nav-link "
                      }
                    >
                      {t("active_contests")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/sponsor"
                      onClick={() => {
                        //setVisibleModel('sponsor')
                      }}
                      className={"nav-link "}
                    >
                      {t("Sponsorships")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/store"
                      className={
                        location.pathname == "/store"
                          ? "nav-link active"
                          : "nav-link "
                      }
                    >
                      {t("shop")}
                    </Link>
                  </li>
                  {/* <li className="ss-shop">
                                        <div className="dropdown lang-dropdown">
                                            <a className="dropdown-toggle1" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span>
                                                    <img
                                                        src="/images/icons/language.svg"
                                                        alt="language"
                                                    />
                                                </span>
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-center mt-2" aria-labelledby="dropdownMenuLink">
                                                {languages?.data?.map((lang: any, index: number) => {
                                                    return (
                                                        <li key={index} onClick={() => { onChangeLang(lang.language_code) }} >
                                                            <a className={languages?.data?.length - 1 === index ? "dropdown-item" : "dropdown-item border-bottom"} href="#">
                                                                <img src={`/images/${lang.language_code}.png`} alt="language" className="pe-2" />
                                                                {lang.language_name}
                                                            </a>
                                                        </li>)
                                                })}
                                            </ul>
                                        </div>
                                    </li> */}
                </ul>
                <ul className="buy-media text-center">
                  <li>
                    <Link to="" className="link-black">
                      <span>
                        <img src="/images/icons/camera.svg" alt="shop" />
                      </span>
                      {localNumber(profile?.media_credit)}
                    </Link>
                  </li>
                  <li className="me-4">
                    <Link
                      to="/buy-media-credit"
                      className="btn btn-orange box-shadow buy-media-button"
                    >
                      {t("buy_media_credits")}
                    </Link>
                  </li>
                  <li>
                    <div className="store">
                      <ul className="buy-media">
                        <li>
                          <a
                            href="#"
                            className="btn btn-orange box-shadow cart-icon"
                            onClick={() => {
                              setShowCartModel(!showCartModel)
                              listDataFun()
                            }}
                          >
                            <img
                              src="/images/icons/cart.svg"
                              height="22"
                              className="me-2"
                            />
                            <span>{totalCount}</span>
                            {t("cart")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>

      {showModal == "sponsor" ? (
        <SponsorShip setVisibleModel={setVisibleModel} />
      ) : null}
    </>
  )
}
