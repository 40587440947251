import { memo, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { showErrorToast, showSuccessToast } from "../utils/commonFun"
import { addToCart, getCartCount } from "../stores/cartSlice"
import { useSelector } from "react-redux"
import { useAppDispatch } from "./../stores/hooks"

const ProductDetail = (props: any) => {
  const { productDetail } = props
  const [selectedVarient, setSelectedVarient] = useState<any>([])
  const [activeImg, setActiveImg] = useState(0)
  const [productQuantity, setProductQuantity] = useState(1)
  const [maxQuantities, setMaxQuantities] = useState(1)
  const [cardItem, setCardItem] = useState<any>([])
  const [selectedColor, setSelectedColor] = useState("")
  const [selectedSize, setSelectedSize] = useState("")
  const [selectedVariantId, setSelectedVariantId] = useState("")
  const [selectedVariantPrice, setSelectedVariantPrice] = useState(0)
  const { t } = useTranslation()
  const { totalCount, cart } = useSelector((state: any) => state.cart)

  const dispatch = useAppDispatch()

  useEffect(() => {
    setCardItem(cart)
  }, [])

  useEffect(() => {
    let isSet = false
    if (productDetail.id) {
      productDetail.variants.forEach((variant: any) => {
        if (variant.is_default) {
          setSelectedVarient(variant.options)
          setSelectedVariantPrice(variant.price / 100)
          setSelectedVariantId(variant?.id)
          setMaxQuantities(variant?.quantity)
          isSet = true
        }
      })
    }
    if (!isSet) {
      setSelectedVarient([])
    }
  }, [productDetail.id])

  const getVariantId = () => {
    let _variant_id = null
    let _price = 0
    for (let i = 0; i < productDetail?.variants.length; i++) {
      if (
        productDetail?.variants[i]?.options[0] == selectedVarient[0] &&
        productDetail?.variants[i]?.options[1] == selectedVarient[1]
      ) {
        _variant_id = productDetail?.variants[i]?.id
        _price = productDetail?.variants[i]?.price / 100
        break
      }
    }
    setSelectedVariantId(_variant_id)
    setSelectedVariantPrice(_price)
  }

  const renderColorOptions = () => {
    return (
      <>
        {productDetail?.options.map((option: any, opindex: number) => {
          return (
            option.name != "Colors" && (
              <div
                key={`options${opindex}`}
                className="font18 font-w500 options"
              >
                <div>{option.name}:</div>
                {option.values.map((color: any, index: number) => {
                  return (
                    <button
                      key={`color${index}`}
                      className={
                        Number(selectedVarient[opindex]) === Number(color?.id)
                          ? "op-btn active-box"
                          : "op-btn"
                      }
                      onClick={() => {
                        let _selectedVarient = [...selectedVarient]
                        _selectedVarient[opindex] = color.id
                        setSelectedVarient(_selectedVarient)
                        if (option.type == "color") {
                          setSelectedColor(color?.title)
                        } else {
                          setSelectedSize(color?.title)
                        }
                        getVariantId()
                      }}
                    >
                      {option.type === "color" ? (
                        <span
                          className="color-circle"
                          style={{
                            backgroundColor: `${color?.colors[0]}`,
                          }}
                        ></span>
                      ) : (
                        color.title
                      )}
                    </button>
                  )
                })}
              </div>
            )
          )
        })}
      </>
    )
  }

  const checkValidQuantities = (_selectedItem: any) => {
    var selectedItem = { ..._selectedItem, productQuantity: productQuantity }
    dispatch(addToCart(selectedItem))
    showSuccessToast(t("Item Added to cart successfully"))
    setProductQuantity(1)
  }

  return (
    <div className="ss-product-details-modle">
      <div
        className="modal fade"
        id="Product-Details-Modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body">
              {productDetail.id ? (
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="slider-flexslider">
                      <div className="storeproduct">
                        <div className="store-pro-bg">
                          <div id="carousel" className="flexslider thumbe">
                            <div className="carousel slide" data-bs-ride="true">
                              <div className="carousel-indicators">
                                {productDetail.images?.map(
                                  (image: any, i: number) => {
                                    return (
                                      <button
                                        id={`dot_${i}`}
                                        type="button"
                                        className={i == activeImg && "active"}
                                        onClick={() => {
                                          setActiveImg(i)
                                        }}
                                        data-bs-target={`#storepro`}
                                        data-bs-slide-to={`${i}`}
                                        aria-current="true"
                                        aria-label={`Slide ${i}`}
                                      ></button>
                                    )
                                  }
                                )}
                              </div>
                              <div className="carousel-inner">
                                {productDetail.images?.map(
                                  (image: any, index: number) => {
                                    return (
                                      <div
                                        key={`proImg_${index}`}
                                        className={
                                          index == activeImg
                                            ? "carousel-item active"
                                            : "carousel-item"
                                        }
                                      >
                                        <img
                                          width={"300px"}
                                          src={
                                            productDetail?.images[index]?.src
                                          }
                                          className="d-block m-auto"
                                          alt="tshirt"
                                        />
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* <div className="font20 font-w500">{productDetail.title}</div> */}
                    <div className="font32 text-black">
                      {productDetail.title}
                    </div>
                    <div className="price">{selectedVariantPrice} CAD</div>
                    <p
                      className="font15"
                      dangerouslySetInnerHTML={{
                        __html: productDetail.description,
                      }}
                    ></p>
                    {renderColorOptions()}
                    <div className="my-4">
                      <div className="row align-items-center">
                        <div className="col-4">
                          <div className="input-group">
                            <button
                              className="input-group-text"
                              onClick={() => {
                                setProductQuantity(productQuantity - 1)
                              }}
                              disabled={productQuantity == 1}
                            >
                              <img src="/images/icons/minus-icon.svg" />
                            </button>
                            <input
                              type="number"
                              className="form-control"
                              value={productQuantity}
                              min="1"
                              aria-label="Amount (to the nearest dollar)"
                            />
                            <button
                              className="input-group-text"
                              onClick={() => {
                                setProductQuantity(productQuantity + 1)
                                // dispatch(incrementQuantity())
                              }}
                            >
                              <img src="/images/icons/plus-icon.svg" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn btn-orange view-more-btn"
                        onClick={() => {
                          let _variant_id = null
                          let _price = 0
                          for (
                            let i = 0;
                            i < productDetail?.variants.length;
                            i++
                          ) {
                            if (
                              productDetail?.variants[i]?.options[0] ==
                                selectedVarient[0] &&
                              productDetail?.variants[i]?.options[1] ==
                                selectedVarient[1]
                            ) {
                              _variant_id = productDetail?.variants[i]?.id
                              _price = productDetail?.variants[i]?.price / 100
                              break
                            }
                          }
                          var _selectedItem = {
                            product_id: productDetail?.id,
                            quantity: productQuantity,
                            variant_id: _variant_id,
                            name: productDetail?.title,
                            color: selectedColor,
                            size: selectedSize,
                            price: _price,
                            img: productDetail?.images[0]?.src,
                          }

                          if (_selectedItem.variant_id == null) {
                            showErrorToast(t("Vaient is not avaliable"))
                          } else {
                            checkValidQuantities(_selectedItem)
                          }
                        }}
                        data-bs-dismiss="modal"
                      >
                        {t("Add to cart")}
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(ProductDetail)
